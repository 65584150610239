import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { passwordResetLink, clickForgotPassword } from '../../actions/auth';
import Loader from '../loader/Loader';

import Logo from '../../img/logo.png';
import forgot_password_icon from '../../img/forgot_password_icon.png';
import checkyouremail_logo from '../../img/checkyouremail_logo.png';

import '../../css/onBoarding/forgotpassword.css';
import '../../css/onBoarding/checkyouremail.css';

const ForgotPassword = ({
  passwordResetLink,
  isAuthenticated,
  isResetLinkSent,
  msg,
  clickForgotPassword,
}) => {
  const [formData, setFormData] = useState({
    email: '',
  });

  const [loading, setLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false); // New state variable

  useEffect(() => {
    document.body.classList.add('with-background-4');

    return () => {
      document.body.classList.remove('with-background-4');
    };
  }, []);

  // Destructure formData
  const { email } = formData;

  const navigate = useNavigate();

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    // Reset the button's disabled state
    setIsButtonClicked(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsButtonClicked(true); // Set the button as clicked
    passwordResetLink(email)
      .then((e) => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    // console.log('Success');
  };

  const handleClickForgotPassword = async (e) => {
    e.preventDefault();

    // console.log('click on event');

    // Call action here
    await clickForgotPassword();
    // console.log('below action function');

    //navigate to link page
    navigate('/email-forgot-password');
    // console.log('below navigate');
  };

  // Redirect if logged in
  // if (isAuthenticated) {
  //   return <Navigate to='/dashboard' />;
  // }

  const loginPage = (e) => {
    e.preventDefault();
    <Navigate to='/login' />;
  };

  const checkYourEmail = (
    <>
      <h2 className='main-text-1 sm:pt-9 2xl:pt-14 font-medium'>
        Check Your Email
      </h2>

      <p className='sub-text-1 sm:pt-2 2xl:pt-5 font-medium'>
        A password reset email has been sent to your <br></br>
        registered email address. Kindly refer to the email <br></br>
        instructions to recover your password.
      </p>
      <div className='flex justify-center sm:pt-4 2xl:pt-5'>
        <p className='password-login-link text-center items-center text-sm font-medium mt-4 mb-1'>
          Didn't receive the link? &nbsp;
          <Link
            to='/email-forgot-password'
            className='password-resend-link  hover:no-underline'
            onClick={(e) => {
              handleClickForgotPassword(e);
            }}
          >
            Resend Link
          </Link>
        </p>
      </div>
      <div className='flex justify-center '>
        <p className='password-login-text text-center items-center text-xs'>
          Didn't receive the email? Check your spam folder
        </p>
      </div>
    </>
  );

  const sendEmail = (
    <>
      <h2 className='forgot-password-heading font-medium sm:pt-9 2xl:pt-14'>
        Forgot Password
      </h2>
      <p className='flex justify-center forgot-password-note font-medium sm:pt-2 2xl:pt-2 sm:pb-9 2xl:pb-14'>
        Enter your registered email address below to receive a link to reset
        your password.
      </p>
      <form className='w-full' onSubmit={(e) => onSubmit(e)} method='POST'>
        <input type='hidden' name='remember' defaultValue='true' />
        <input
          id='email-address'
          name='email'
          type='email'
          value={email}
          onChange={(e) => onChange(e)}
          autoComplete='email'
          required
          placeholder='Email'
          className='password-register-input-field password-register-input-field-1 w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative '
        />
        <div className='flex-row justify-center sm:pt-7 2xl:pt-7 w-full'>
          <button
            className={`password-register-button password-register-button-1 px-4 py-2 rounded-lg w-full focus:ring-2 focus:ring-offset-2 focus:ring-button_ring-10 tracking-wider focus:outline-none ${
              isButtonClicked ? 'disabled-button' : ''
            }`}
            disabled={isButtonClicked}
          >
            Send Link
          </button>
          {/* </Link> */}
          <p className='text-center flex justify-center items-center '>
            <Link
              to='/Login'
              className='flex items-center password-back-to-login-link password-back-to-login-link-1 sm:pt-1 2xl:pt-1 no-underline mt-2'
            >
              <div className='flex items-center'>
                <i class='items-center fa-solid fa-chevron-left  mr-2 text-xs forgot-password-arrow'></i>
                <span className=' flex items-center'>Back to Login</span>
              </div>
            </Link>
          </p>
        </div>
      </form>
    </>
  );

  return (
    <>
      <div className='flex flex-col min-h-screen page'>
        <div className='flex justify-center sm:pt-4 2xl:pt-7'>
          <Link to='/login'>
            <img src={Logo} alt='Ecom Next Price Now' className='logo-style' />
          </Link>
        </div>
        <div className='flex flex-grow items-center justify-center'>
          <div className='text-center flex flex-col items-center w-72'>
            <img
              src={
                !isResetLinkSent ? forgot_password_icon : checkyouremail_logo
              }
              alt='forgotPassMail'
              className={
                !isResetLinkSent ? 'forgot-password-img' : ' checkyouremail-img'
              }
            />
            {!isResetLinkSent ? sendEmail : checkYourEmail}
          </div>
        </div>
      </div>
    </>
  );
};

ForgotPassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  passwordResetLink: PropTypes.func.isRequired,
  // isAuthenticated: PropTypes.bool.isRequired,
  isResetLinkSent: PropTypes.bool.isRequired,
  // msg: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isResetLinkSent: state.auth.isResetLinkSent,
  msg: state.auth.msg,
});

export default connect(mapStateToProps, {
  passwordResetLink,
  clickForgotPassword,
})(ForgotPassword);
