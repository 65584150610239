import { Fragment, useEffect, useState } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import { Link, useLocation } from 'react-router-dom';

import HamburgerNavBar from '../layout/dropdowns/HamburgerNavBar';
import defaultProfilePic from '../../img/defaultProfilePic.png';
import color_logo from '../../img/logo.png';
import white_logo from '../../img/white_logo.png';
import FIlterIcon from '../../img/filter_icon.png';

import '../../css/navbar/navbar.css';
import ProfilePic from '../layout/profile/ProfilePic';

const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

let Navbar = ({
  auth: { isAuthenticated, user, loading, fullNavBar },
  logout,
  handleSidebarToggle,
}) => {
  const [showManageProfile, setShowManageProfile] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();
  const isHomePage =
    location.pathname === '/homepage' ||
    location.pathname === '/edit-profile' ||
    location.pathname === '/profile' ||
    location.pathname === '/pd-plans' ||
    location.pathname === '/contact-us' ||
    location.pathname === '/pay-fail' ||
    location.pathname === '/pay-success' ||
    location.pathname === '/user-subscription';

  const isAnalyticsMobile = location.pathname === '/analytics';
  const isPDPlansPage = location.pathname === '/pd-plans';
  const imageSrc = isPDPlansPage && !isMobile ? white_logo : color_logo;
  const userNameClass =
    isPDPlansPage && !isMobile ? 'user-name-white' : 'user-name';

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const authLinks_1 = (
    <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
      <Menu as='div' className='ml-3 relative'>
        <div>
          <Menu.Button className='bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'>
            <span className='sr-only'>Open user menu</span>
            <img
              className='h-8 w-8 rounded-full'
              src={defaultProfilePic}
              alt=''
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to='/profile'
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700'
                  )}
                >
                  Your Profile
                </Link>
              )}
            </Menu.Item>
            {showManageProfile && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    href='/manage-profile'
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700'
                    )}
                  >
                    Manage profile
                  </a>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <a
                  href='/login'
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700'
                  )}
                  onClick={logout}
                >
                  Sign out
                </a>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );

  const authLinks_2 = (
    <div className='hidden sm:block sm:ml-6'>
      <div className='flex space-x-4'>
        {navigation.map((item) => (
          <a
            key={item.name}
            href={item.href}
            className={classNames(
              item.current
                ? 'bg-gray-900 text-white'
                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
              'px-3 py-2 rounded-md text-sm font-medium'
            )}
            aria-current={item.current ? 'page' : undefined}
          >
            {item.name}
          </a>
        ))}
      </div>
    </div>
  );

  return (
    <nav className='bg-transparent py-2 px-8 flex justify-between items-center nav-wrapper'>
      {/* Logo and text on the left */}
      <div className='flex items-center'>
        {isHomePage && (
          <Link to='/homepage' className='link-style-navbar'>
            <img src={imageSrc} alt='navbar_logo' className='nav-bar-logo' />
          </Link>
        )}
        {isAnalyticsMobile && isMobile && (
          <div className='flex'>
            <img
              src={FIlterIcon}
              alt='filter-icon'
              className='mobile-filter-icon ml-2 mr-2'
              onClick={handleSidebarToggle}
            />
            <Link to='/homepage' className='link-style-navbar-mobile ml-1'>
              <img src={imageSrc} alt='navbar_logo' className='nav-bar-logo' />
            </Link>
          </div>
          // <i
          //   className='fa-solid fa-filter mobile-filter-icon'
          //   onClick={handleSidebarToggle}
          // ></i>
        )}
      </div>

      {/* Profile picture, user name, and dropdown on the right */}
      <div className='flex items-center'>
        <div className='flex items-center mr-4'>
          <ProfilePic defaultProfilePic={defaultProfilePic} />
          {user && user.user_name && (
            <span className={userNameClass}>{user.user_name}</span>
          )}
        </div>
        <HamburgerNavBar />
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Navbar);
