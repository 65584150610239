import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

//Image
import pro_plan_icon from '../../../img/pro_plan_icon.png';

//component
import { savePremiumSubDetails } from '../../../actions/products';

//CSS
import '../../../css/layout/cards/PriceCard.css';

// Stripe
import { loadStripe } from '@stripe/stripe-js';

const ProPlanCard = ({
  auth: { isAuthenticated, user },
  savePremiumSubDetails,
  yearly,
  selectedplatform,
  industryAndPlatformData,
}) => {
  const [basePrice, setBasePrice] = useState(7999);
  const [planType, setPlanType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [userEmail, setUserEmail] = useState();
  const [userMobile, setUserMobile] = useState();
  const [userName, setUserName] = useState();

  const [currency, setCurrency] = useState('');
  const [userIp, setUserIp] = useState('');
  const [calculatedPrice, setCalculatedPrice] = useState(null); // State to store the calculated price

  useEffect(() => {
    if (user) {
      setPlanType(user.plan_type);
      setUserEmail(user.user_email);
      setUserMobile(user.user_mobile_number);
      setUserName(user.user_name);
    }
  }, [user]);

  const navigate = useNavigate(); // <-- Initialize useNavigate hook

  // console.log('yearly', yearly);
  // console.log('selectedplatform', selectedplatform);

  // Fetch User's IP Address
  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setUserIp(data.ip);

        // Save the IP address in local storage
        localStorage.setItem('userIp', data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    // Check if the user's IP address is already saved in local storage
    const savedUserIp = localStorage.getItem('userIp');
    if (savedUserIp) {
      // console.log('saved user Ip');
      setUserIp(savedUserIp);
    } else {
      // Fetch the user's IP address if it's not saved in local storage
      fetchIpAddress();
    }
  }, []);

  // Fetch Currency based on user's IP address
  useEffect(() => {
    const fetchData = async () => {
      if (!userIp) {
        return; // Wait until userIp is available
      }

      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };

        const body = JSON.stringify({
          ip: userIp,
        });

        const res = await axios.post(
          `${process.env.REACT_APP_API_URI}/price-now/api/get-currency`,
          body,
          config
        );

        setCurrency(res.data.currency);

        // console.log('user Ip curr', res.data.currency);
      } catch (error) {
        console.error('Error fetching currency:', error);
      }
    };

    fetchData();
  }, [userIp]);

  // Calculate price whenever selectedplatform.length changes
  useEffect(() => {
    // Implement your price calculation logic here based on the selected platform
    let calculatedPriceValue;
    if (selectedplatform.length !== 0) {
      if (yearly) {
        calculatedPriceValue =
          basePrice * 12 * selectedplatform.length -
          basePrice * 12 * 0.25 * selectedplatform.length;
      } else {
        calculatedPriceValue = basePrice * selectedplatform.length;
      }
    } else {
      calculatedPriceValue = 0; // Set price to 0 if no platform is selected
    }
    setCalculatedPrice(calculatedPriceValue);
  }, [selectedplatform, yearly]);

  // Redirect and complete the payment
  const makePayment = async () => {
    // Check if user.plan_type is pro
    // console.log('.....', planType);
    if (planType === 'premium') {
      toast.error('Already a Premium user, select premium plan only');
      return;
    }

    // Check if selected platforms are available
    if (selectedplatform.length === 0) {
      toast.error('Please select valid platforms');
      return;
    }

    let amount = calculatedPrice;

    const items = [
      {
        name: 'Pro Plan',
        price: amount,
        quantity: 1,
      },
    ];

    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    const plan_type = 'pro';

    const body = JSON.stringify({
      products: items,
      currency: currency,
      amount,
      plan_type,
      duration: yearly ? 'y' : 'm',
      industryAndPlatformData,
      selectedplatform,
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URI}/price-now/api/create-razorpay-order`,
        body,
        config
      );

      const orderData = res.data.order;

      var options = {
        key: 'rzp_test_hW98I8ttme2Tdq', // Enter the Key ID generated from the Dashboard
        amount: orderData.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: orderData.currency,
        name: 'Inference Labs', //your business name
        description: 'Test Transaction',
        image:
          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4_sLIK2t0j1hSniCVpeqAGvPb_8B8EEv0bZaptyxuCItzKemmsJg0I-0rEz6KQYccFh0&usqp=CAU',
        order_id: orderData.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1

        handler: async function (response) {
          try {
            // Make a request to your backend API to verify the payment
            const verifyPaymentResponse = await axios.post(
              `${process.env.REACT_APP_API_URI}/price-now/api/verify-payment`,
              {
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
                data: body, // Pass the body object used for creating payment intent
              },
              config
            );

            // console.log('Verify Payment Response:', verifyPaymentResponse);

            // Redirect to the success URL if payment verification is successful
            window.location.href = `https://pricenow.ecomnext.ai/pay-success?payment_id=${response.razorpay_payment_id}`;
          } catch (error) {
            console.error('Error verifying payment:', error);

            // Handle the payment verification failure
            toast.error('Payment verification failed');
          }
        },
        prefill: {
          //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
          name: userName, //your customer's name
          email: userEmail,
          contact: userMobile, //Provide the customer's phone number for better conversion rates
        },
        notes: {
          address: 'Razorpay Corporate Office',
        },
        theme: {
          color: '#3399cc',
        },
      };
      var rzp1 = new window.Razorpay(options);

      rzp1.on('payment.failed', function (response) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
        // Redirect to the success URL if payment verification is successful
        // window.location.href = `${process.env.REACT_WEB_URL}/pay-fail`;
        toast.error('Payment failed, Please Retry');
      });
      rzp1.open();
    } catch (error) {
      console.error('Error making payment:', error);
      toast.error('Payment failed');
    }
  };

  if (currency) {
    // console.log('User currency ', currency);
  }

  return (
    <div className='max-w-sm overflow-hidden bg-white shadow-lg rounded-lg plan-card-div'>
      {/* Top Section */}
      <div className='flex items-center justify-between p-2 bg-white text-white'>
        {/* Left side (image) */}
        <div className='flex-shrink-0'>
          <img className='plan-card-icon' src={pro_plan_icon} alt='bsic Icon' />
        </div>

        {/* Right side (text: premium and price) */}
        <div className='text-right'>
          <p className='plan-card-title'>Pro</p>
          {yearly ? (
            <>
              <p className='plan-card-price'>
                ₹{calculatedPrice} <span className='per-user'>/ user</span>
                <span className='discount-text'>25% off</span>
              </p>
              <p className='strike-price'>
                ₹
                {selectedplatform.length !== 0
                  ? basePrice * 12 * selectedplatform.length
                  : 0}{' '}
                {/* Display 0 when selectedplatform.length is 0 */}
              </p>
            </>
          ) : (
            <p className='plan-card-price'>
              ₹{calculatedPrice} <span className='per-user'>/ user</span>
            </p>
          )}
        </div>
      </div>

      {/* Horizontal Line */}
      <hr className='mx-4 border-t border-gray-300' />

      {/* Features Section */}
      <div className='p-4'>
        {/* Features Heading */}
        <p className='plan-card-feature-text'>FEATURES</p>

        {/* List of Features */}
        <ul className='text-sm'>
          <li className='mb-2'>
            <i class='fa-solid fa-circle-check feature-tick-green mr-2'></i>
            Price Monitoring
          </li>
          <li className='mb-2'>
            <i class='fa-solid fa-circle-check feature-tick-green mr-2'></i>
            30 Days Historic Graph
          </li>
          <li className='mb-2'>
            <i class='fa-solid fa-circle-check feature-tick-green mr-2'></i>
            Product Out of Stock Report
          </li>
          <li className='mb-2'>
            <i class='fa-solid fa-circle-check feature-tick-green mr-2'></i>
            New Product launches Report
          </li>
          <li className='mb-2'>
            <i class='fa-solid fa-circle-check feature-tick-green mr-2'></i>
            Reports Download
          </li>
          <li className='mb-2'>
            <i class='fa-solid fa-circle-check feature-tick-green mr-2'></i>
            30 Days Historic Data
          </li>
          <li className='mb-2'>
            <i class='fa-solid fa-circle-check feature-tick-green mr-2'></i>
            Twice Data Refresh
          </li>

          {/* Add more features as needed */}
        </ul>
      </div>

      {/* Button Section */}
      <div className='flex justify-center mt-2'>
        <button
          className='plan-card-button focus:ring-offset-2 focus:ring-2 focus:ring-button_ring-10 disabled:opacity-75 disabled:pointer-events-none'
          onClick={makePayment}
        >
          Buy <i className='fa-solid fa-arrow-right text-xs'></i>
        </button>
      </div>
    </div>
  );
};

ProPlanCard.propTypes = {
  savePremiumSubDetails: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { savePremiumSubDetails })(ProPlanCard);
