import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../img/logo.png';
import checkyouremail_logo from '../../img/checkyouremail_logo.png';
import '../../css/onBoarding/checkyouremail.css';

const CheckYourEmail = () => {
  return (
    <div className='flex flex-col min-h-screen checkyouremail-page'>
      <div className='flex justify-center pt-4'>
        <img src={Logo} alt='Invalid Link' className='logo-style-1' />
      </div>
      <div className='flex flex-grow items-center justify-center'>
        <div className='text-center flex flex-col items-center checkyouremail-box-size'>
          <img
            src={checkyouremail_logo}
            alt='Check Your Email'
            className='email-img-style'
          />
          <h2 className='main-text-1 sm:pt-9 2xl:pt-14 font-medium'>
            Check Your Email
          </h2>

          <p className='sub-text-1 sm:pt-9 2xl:pt-14 font-medium'>
            A password reset email has been sent to your
            <br></br>
            registered email address. Kindly refer to the
            <br></br>
            email instructions to recover your password.
          </p>
          <div className='flex justify-center sm:pt-9 2xl:pt-14'>
            <Link
              to='/forgot-password'
              className='flex justify-center items-center checkyouremail-button-link checkyouremail-button-link-1 py-2 px-4 border border-transparentfocus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hover_1-10 tracking-wider hover:no-underline'
            >
              OK
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckYourEmail;
