import axios from 'axios';

import {
  USER_LIST_SUCCESS,
  USER_LIST_FAILED,
  USER_DETAILS_SUCCESS,
  USER_SUBSCRIPTION_DATA_SUCCESS,
  USER_SUBSCRIPTION_DATA_FAILED,
} from './types';
import { toast } from 'react-toastify';

// CLEAR USER RECORDS
export const clearUserRecords = () => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'Application/json',
    },
  };

  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_API_URI}/price-now/api/clear-record`,
      config
    );

    return res;
  } catch (err) {
    console.log('errors', err.response.data.errors);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        toast.error(error.msg);
      });
    }
  }
};

// GET USERS SUBSCRIPTIONS (PREM/PRO )
export const getUserSubscription = () => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'Application/json',
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URI}/price-now/api/user-subscription-details`,
      config
    );

    // dispatch({
    //   type: USER_SUBSCRIPTION_DATA_SUCCESS,
    //   payload: res.data,
    // });

    return res;
  } catch (err) {
    console.log('errors', err.response.data.errors);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        toast.error(error.msg);
      });
    }

    // dispatch({
    //   type: USER_SUBSCRIPTION_DATA_FAILED,
    // });
  }
};
