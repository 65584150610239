import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../img/logo.png';
import InvalidLinkLogo from '../../img/invalid_Link_Logo.png';
import '../../css/onBoarding/invalidlink.css';

const InvalidLink = () => {
  return (
    <div className='flex flex-col h-screen h-width page'>
      <div className='flex justify-center sm:pt-4 2xl:pt-7'>
        <img src={Logo} alt='Invalid Link' className='logo-style ' />
      </div>
      <div className='flex flex-grow items-center justify-center'>
        <div className='text-center flex flex-col items-center'>
          <img
            src={InvalidLinkLogo}
            alt='Invalid Link'
            className='img-style '
          />
          <p className='invalid-link-heading sm:pt-9 2xl:pt-14 font-medium'>
            Invalid Link
          </p>

          <p className='flex justify-center invaild-link-note sm:pt-9 2xl:pt-14 font-medium text-justify'>
            We regret to inform you that the reset password link you attempted
            to use is invalid. Please make a new request to reset your password.
          </p>
          <div className='flex justify-center sm:pt-9 2xl:pt-14'>
            {/* <Link
              to='/forgot-password'
              className='flex justify-center items-center  invalid-button-link  invalid-button-link-1   px-4 py-2   rounded-lg w-full focus:ring-2 focus:ring-offset-2 focus:ring-button_ring-10 tracking-wider focus:outline-none'
            >
              Back To forget password
            </Link> */}
            {/* <Link to='/forgot-password'>
              <button
                type='submit'
                className='flex justify-center items-center invalid-button-link  invalid-button-link-1 px-4 py-2   rounded-lg w-full sm:w-auto focus:ring-2 focus:ring-offset-2 focus:ring-button_ring-10 tracking-wider focus:outline-none '
              >
                Back To forget password
              </button>
            </Link> */}
            <Link to='/email-forgot-password' className='hover:no-underline'>
              <button
                type='button'
                className='invalid-button-link  invalid-button-link-1 flex justify-center items-center px-4 py-2   rounded-lg  sm:w-auto focus:ring-2 focus:ring-offset-2 focus:ring-button_ring-10 tracking-wider focus:outline-none no-underline'
              >
                Back To forget password
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvalidLink;
