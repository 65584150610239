import React, { useState, useEffect } from 'react';
import pricing_bg from '../../img/pricing_bg.png';
import axios from 'axios';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import '../../css/planAndPricing/paidPlans.css';

import Loader2 from '../loader/Loader2';
import Navbar from '../navbar/Navbar';
import BasicPlanCard from '../layout/cards/BasicPlanCard';
import PremiumPlanCard from '../layout/cards/PremiumPlanCard';
import ProPlanCard from '../layout/cards/ProPlanCard';
import SingleSelectionDropdown from '../layout/dropdowns/SingleSelectionDropdown';
import MultiSelectDropdown from '../layout/dropdowns/MultiSelectDropdown';
import CustomPlanCard from '../layout/cards/CustomPlanCard';

const PaidPlans = () => {
  const [yearly, setYearly] = useState(true);
  const [loadingList, setLoadingList] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [industryInputValues, setIndustryInputValues] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState('');
  const [categoryInputValues, setCategoryInputValues] = useState([]);

  const [selectedplatform, setSelectedPlatform] = useState([]);
  const [platformInputValues, setPlatformInputValues] = useState([]);

  const [industryAndPlatformData, setIndustryAndPlatformData] = useState([]);
  const [currentPlatformSelection, setCurrentPlatformSelection] = useState([]);

  const [subscribedPlatformsId, setSubscribedPlatformsId] = useState([]);

  useEffect(() => {
    const fetchIndustryValue = async () => {
      try {
        setLoadingList(true);
        // Make an API call to fetch industry values
        const config = {
          headers: {
            'Content-Type': 'Application/json',
          },
        };

        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/price-now/api/industry-list`,
          config
        );

        if (response.data && response.data.industry_list) {
          setIndustryInputValues(response.data.industry_list);
          // Filter industries where selected is true
          const selectedIndustry = response.data.industry_list.find(
            (industry) => industry.selected
          );

          setSelectedIndustry(selectedIndustry || null);
          setLoadingList(false);
        }
      } catch (error) {
        console.error(error);
        // Handle the error as needed
        setLoadingList(false);
      }
    };

    fetchIndustryValue();
  }, []);

  useEffect(() => {
    setSelectedCategory('');
    setPlatformInputValues([]);
    const fetchCategoryList = async (industryId) => {
      try {
        setLoadingList(true);
        // Make an API call to fetch category list based on industryId
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/price-now/api/category-list?industryId=${industryId}`
        );

        if (response.data && response.data.category_list) {
          setCategoryInputValues(response.data.category_list);
          // Process the category list as needed
          // console.log('Category List:', response.data.category_list);
        }

        setLoadingList(false);
      } catch (error) {
        console.error(error);
        // Handle the error as needed
        setLoadingList(false);
      }
    };

    // Check if selectedIndustry is truthy and has an id property
    if (selectedIndustry && selectedIndustry.id) {
      // Call the fetchCategoryList function with the industry id
      fetchCategoryList(selectedIndustry.id);
    }
  }, [selectedIndustry]);

  useEffect(() => {
    // setSelectedPlatform([]);
    const fetchPlatformList = async (categoryId) => {
      try {
        setLoadingList(true);
        // Make an API call to fetch Platform list based on categoryId
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/price-now/api/platform-list?categoryId=${categoryId}`
        );

        if (response.data && response.data.platform_list) {
          setPlatformInputValues(response.data.platform_list);
          // Process the category list as needed
          // console.log('Platform List:', response.data.platform_list);
          setCurrentPlatformSelection([]);

          // Call API to Fetch Platform List(Id's) using Selected Industry Category value
          // console.log('selectedCategory', selectedCategory.category_value);
          // console.log('selectedIndutry data', selectedIndustry.industry_value);
          // console.log('platform data list', response.data.platform_list);

          // Fetch Subscribed platform list
          const subscribedPlatforms = await axios.get(
            `${process.env.REACT_APP_API_URI}/price-now/api/subscribed-platforms?category_value=${selectedCategory.category_value}&industry_value=${selectedIndustry.industry_value}`
          );

          setSubscribedPlatformsId(
            subscribedPlatforms.data.subscribedPlatforms
          );
          // console.log('subscribedPlatforms', subscribedPlatforms);
        }

        setLoadingList(false);
      } catch (error) {
        console.error(error);
        // Handle the error as needed
        setLoadingList(false);
      }
    };

    // Check if selectedIndustry is truthy and has an id property
    if (selectedCategory && selectedCategory.id) {
      // Call the fetchCategoryList function with the industry id
      fetchPlatformList(selectedCategory.id);
    }
  }, [selectedCategory]);

  if (subscribedPlatformsId) {
    // console.log('plat Idddddddddd', subscribedPlatformsId);
  }

  // Listen for changes in selectedPlatform
  useEffect(() => {
    if (currentPlatformSelection.length > 0) {
      updateIndustryAndPlatformData();
    }
  }, [currentPlatformSelection]);

  const updateIndustryAndPlatformData = () => {
    // console.log(
    //   'currentPlatformSelection test value',
    //   currentPlatformSelection
    // );
    const newData = [...industryAndPlatformData];
    let updated = false;

    // Check if an entry already exists for the current industry and category
    const existingIndex = newData.findIndex(
      (item) =>
        item.industry === selectedIndustry.industry_value &&
        item.category === selectedCategory.category_value
    );

    // If an entry exists, update its platform list
    if (existingIndex !== -1) {
      const existingItem = newData[existingIndex];
      currentPlatformSelection.forEach((platform) => {
        if (!existingItem.platform.includes(platform)) {
          existingItem.platform.push(platform);
          updated = true;
        }
      });
    } else {
      // If no entry exists, create a new entry
      newData.push({
        industry: selectedIndustry.industry_value,
        category: selectedCategory.category_value,
        platform: [...currentPlatformSelection],
      });
      updated = true;
    }

    // Update the state only if there were updates
    if (updated) {
      setIndustryAndPlatformData(newData);
    }
  };

  const handleToggle = () => {
    setYearly(!yearly);
  };

  // console.log('selectedIndustry', selectedIndustry);
  // console.log('industryInputValues', industryInputValues);

  return (
    <div className='paid-plans-container'>
      {/* First part with image background */}
      <div className='image-background'>
        {/* Image content goes here */}
        {/* <img src={pricing_bg} alt='Pricing Background' /> */}
        <div className='width-full sticky-container'>
          {/* Absolute content for the first part */}
          <Navbar />
          {loadingList && <Loader2 loading={loadingList} />}
          <div className='content-text w-100 flex flex-column'>
            <span className='text-1'>Explore and choose the right plan</span>
            <span className='text-2'>
              Choose the plan that's right for you and your team
            </span>
          </div>

          <div className='dropdown-toggle-container flex gap-4 mt-4 justify-center items-center'>
            {/* First Dropdown */}
            <SingleSelectionDropdown
              searchInputValues={industryInputValues}
              selected={selectedIndustry}
              setSelected={setSelectedIndustry}
            />

            {/* Second Dropdown */}
            <div className='relative'>
              <SingleSelectionDropdown
                searchInputValues={categoryInputValues}
                selected={selectedCategory}
                setSelected={setSelectedCategory}
              />
            </div>

            {/* Third Dropdown */}
            <div className='relative'>
              {platformInputValues != null ? (
                <MultiSelectDropdown
                  platformValues={platformInputValues}
                  selectedplatform={selectedplatform}
                  setSelectedplatform={setSelectedPlatform}
                  currentPlatformSelection={currentPlatformSelection}
                  setCurrentPlatformSelection={setCurrentPlatformSelection}
                  subscribedPlatformsId={subscribedPlatformsId}
                />
              ) : (
                ''
              )}
            </div>

            <div className='toggle-button-wrapper flex justify-center mobile-view'>
              <div className='toggle-button-div flex items-center'>
                <button
                  className={`${
                    yearly ? 'inactive-plan-button' : 'active-plan-button'
                  } mr-1`}
                  onClick={handleToggle}
                >
                  Monthly
                </button>
                <button
                  className={`${
                    yearly ? 'active-plan-button' : 'inactive-plan-button'
                  }`}
                  onClick={handleToggle}
                >
                  Yearly
                </button>
              </div>
            </div>
          </div>

          <div className='cards-row flex gap-4 mt-4 justify-center'>
            {/* First Card */}
            <BasicPlanCard />

            {/* Second Card */}
            <PremiumPlanCard
              yearly={yearly}
              selectedplatform={selectedplatform}
              industryAndPlatformData={industryAndPlatformData}
            />

            {/* Third Card */}
            <ProPlanCard
              yearly={yearly}
              selectedplatform={selectedplatform}
              industryAndPlatformData={industryAndPlatformData}
            />

            {/* Fourth Card */}
            <CustomPlanCard />
          </div>
        </div>
      </div>

      {/* Second part with background color */}
      <div className='color-background'>
        {/* Content for the second part goes here */}
        <div className='absolute-content'>
          {/* Absolute content for the second part */}
        </div>
      </div>
    </div>
  );
};

export default PaidPlans;
