import React, { useState, useEffect } from 'react';
import Navbar from '../navbar/Navbar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UserSubscriptionTable from '../layout/tables/UserSubscriptionTable';
import { getUserSubscription } from '../../actions/user';
import { Link } from 'react-router-dom';

//Component
import Loader2 from '../loader/Loader2';

//Import CS
import '../../css/userDetails/userSubscription.css';

const UserSubscriptions = ({
  auth: { isAuthenticated, user },
  getUserSubscription,
}) => {
  const [subscrptionData, setSubscriptionData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add('with-background');

    return () => {
      document.body.classList.remove('with-background');
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        // Fetch user subscriptions
        const response = await getUserSubscription();
        if (response && response.data) {
          // console.log('Data received:', response.data);
          // console.log('Data received:', response.data.data);
          setSubscriptionData(response.data.data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching user subscriptions:', error);
      }
    };

    fetchData();
  }, []);

  // Pagination method
  const getPaginatedItems = (items) => {
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    return items.slice(startIndex, endIndex);
  };

  const handleNextPageClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const totalSubscriptionItems = subscrptionData ? subscrptionData.length : 0;
  const totalPages = Math.ceil(totalSubscriptionItems / 10);

  return (
    <>
      <Navbar />

      {/* Buy More button */}
      <div className='flex justify-end items-center'>
        <Link to='/pd-plans' className='buy-more-button'>
          <i className='fa-solid fa-circle-plus mr-1'></i>Buy More
        </Link>
      </div>

      {isLoading ? (
        <Loader2 />
      ) : (
        <>
          {/* Scrollable Table Container */}
          <div className='table-container'>
            <UserSubscriptionTable
              data={subscrptionData}
              getPaginatedItems={getPaginatedItems}
              setData={setSubscriptionData}
            />
          </div>

          {/* Pagination */}
          <div className='w-full flex justify-center pt-1 pb-5 mt-3'>
            <div className='bg-white text-black items-center flex justify-between rounded-full py-1 shadow-md'>
              <button
                className='prev-button py-1 px-2 focus:outline-none'
                disabled={currentPage === 1}
                onClick={handlePrevPage}
              >
                <i class='fa-solid fa-chevron-left pr-1 pl-1'></i>PREV
              </button>
              <p className='page-count px-3'>
                {currentPage} | {totalPages}
              </p>
              <button
                className=' next-button py-1 px-2 focus:outline-none'
                onClick={handleNextPageClick}
                disabled={currentPage === totalPages}
              >
                NEXT<i class='fa-solid fa-chevron-right pl-1'></i>
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

UserSubscriptions.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  getUserSubscription: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { getUserSubscription })(
  UserSubscriptions
);
