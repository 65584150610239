import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { register } from '../../actions/auth';
import { toast } from 'react-toastify';
import Loader from '../loader/Loader';
import PropTypes from 'prop-types';

// Images
import color_logo from '../../img/color_logo.png';
import user_icon from '../../img/user.png';
import email_icon from '../../img/email.png';
import password_icon from '../../img/password.png';
import phone_icon from '../../img/phone.png';
import company_icon from '../../img/company.png';
import bg_img_1 from '../../img/bg_img_1.png';

// CSS
import '../../css/onBoarding/register.css';
import '../../css/onBoarding/login.css';

const Register = ({ register, isOtpVerification }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
    mobile: '',
    company: '',
  });
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  // Destructure formData
  const { name, email, password, password2, mobile, company } = formData;

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const onChangeMobile = (e) => {
    const { value } = e.target;

    // Check if the entered value is numeric
    if (/^\d*$/.test(value) || value === '') {
      setFormData({
        ...formData,
        mobile: value,
      });
    }
  };

  const handleTermsCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Check if the terms and conditions checkbox is checked
    if (!termsAccepted) {
      toast.error('Please accept the Terms and Conditions.');
      return; // Prevent further execution
    }

    if (password !== password2) {
      // setAlert('Password does not match', 'danger');
      toast.error('Confirm password does not matches!');
      // console.log('Password does not matches');
    } else {
      setLoading(true);
      register({
        name,
        email,
        password,
        mobile,
        company,
      })
        .then((e) => {
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
      // console.log('Success');
    }
  };

  // Redirect if logged in
  if (isOtpVerification && localStorage.getItem('token')) {
    return <Navigate to='/otp-verification' replace={true} />;
  }

  return (
    <div className='flex'>
      {/* Left side */}
      <div className='w-2/5 flex-shrink-0 flex flex-col items-center justify-center left-area'>
        <div className='mb-4 flex justify-center items-center div-1'>
          <img
            src={color_logo}
            alt='color-logo'
            className='color-logo-register'
          />
        </div>
        <div className='flex flex-col items-center justify-center div-2 flex-grow px-16'>
          <h2 className='form-heading'>Register</h2>
          <h5 className='sub-heading'>Join Us Today! Create Your Account</h5>
          <form className='w-full' onSubmit={(e) => onSubmit(e)} method='POST'>
            <input type='hidden' name='remember' defaultValue='true' />
            <div className='mb-4 relative'>
              <img
                src={user_icon}
                alt='User Icon'
                className='absolute left-0 top-1/2 transform -translate-y-1/2 h-6 w-6 text-gray-500 input-icon-register'
                style={{ zIndex: 1 }}
              />
              <input
                id='name'
                name='name'
                type='text'
                value={name}
                onChange={(e) => onChange(e)}
                autoComplete='name'
                required
                maxLength='40'
                placeholder='Full Name'
                className='register-input-field bg-inherit w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative pl-4'
              />
            </div>
            <div className='mb-4 relative'>
              <img
                src={email_icon}
                alt='User Icon'
                className='absolute left-0 top-1/2 transform -translate-y-1/2 h-6 w-6 text-gray-500 input-icon-register'
                style={{ zIndex: 1 }}
              />
              <input
                id='email-address'
                name='email'
                type='email'
                value={email}
                onChange={(e) => onChange(e)}
                autoComplete='email'
                required
                placeholder='Email'
                className='register-input-field bg-inherit w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative pl-4'
              />
            </div>
            <div className='mb-4 relative'>
              <img
                src={password_icon}
                alt='User Icon'
                className='absolute left-0 top-1/2 transform -translate-y-1/2 h-6 w-6 text-gray-500 input-icon-register'
                style={{ zIndex: 1 }}
              />
              <input
                id='password'
                name='password'
                type='password'
                value={password}
                onChange={(e) => onChange(e)}
                autoComplete='current-password'
                required
                placeholder='Password'
                className='password-input bg-inherit w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative pl-4'
              />
            </div>
            <div className='mb-4 relative'>
              <img
                src={password_icon}
                alt='User Icon'
                className='absolute left-0 top-1/2 transform -translate-y-1/2 h-6 w-6 text-gray-500 input-icon-register'
                style={{ zIndex: 1 }}
              />
              <input
                id='password2'
                name='password2'
                type='password'
                value={password2}
                onChange={(e) => onChange(e)}
                required
                placeholder='Confirm Password'
                className='password-input bg-inherit w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative pl-4'
              />
            </div>
            <div className='mb-4 relative'>
              <img
                src={phone_icon}
                alt='User Icon'
                className='absolute left-0 top-1/2 transform -translate-y-1/2 h-6 w-6 text-gray-500 input-icon-register'
                style={{ zIndex: 1 }}
              />
              <input
                id='mobile'
                name='mobile'
                type='tel'
                placeholder='Mobile Number'
                value={mobile}
                // onChange={(e) => onChange(e)}
                onChange={onChangeMobile}
                autoComplete='current-mobile'
                pattern='[0-9]{10}'
                title='Please enter a 10-digit numeric mobile number.'
                inputMode='numeric'
                minLength={10}
                maxLength={10}
                required
                className='register-input-field bg-inherit w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative pl-4'
              />
            </div>
            <div className='mb-4 relative'>
              <img
                src={company_icon}
                alt='User Icon'
                className='absolute left-0 top-1/2 transform -translate-y-1/2 h-6 w-6 text-gray-500 input-icon-register'
                style={{ zIndex: 1 }}
              />
              <input
                id='company'
                name='company'
                type='text'
                placeholder='Company Name'
                value={company}
                onChange={(e) => onChange(e)}
                autoComplete='current-company'
                required
                maxLength='40'
                className='register-input-field bg-inherit w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative pl-4'
              />
            </div>
            <button
              type='submit'
              className='register-button py-2 px-4 rounded-lg w-full mb-4 focus:ring-2 focus:ring-offset-2 focus:ring-button_ring-10 tracking-wider focus:outline-none'
            >
              Register
            </button>

            <div className='mb-2'>
              <label htmlFor='termsCheckbox' className='flex items-center'>
                <input
                  type='checkbox'
                  className='form-checkbox h-3 w-3 text-blue-500 mr-2'
                  id='termsCheckbox'
                  checked={termsAccepted}
                  onChange={handleTermsCheckboxChange}
                />

                <span className='text-gray-700 terms-and-condition'>
                  By creating this account you accept our{' '}
                  {/* <Link to={''} className='terms-and-condition-link'>
                    Terms and Conditions
                  </Link> */}
                  <a
                    href='https://ecomnext.ai/terms-and-conditions/'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='terms-and-condition-link'
                  >
                    Terms and Conditions
                  </a>
                </span>
              </label>
            </div>
          </form>

          {/* OR Line */}
          <div className='flex items-center mb-2 w-full'>
            <hr className='flex-1 border-t border-gray-300' />
            <span className='mx-2 text-sm text-gray-500'>OR</span>
            <hr className='flex-1 border-t border-gray-300' />
          </div>

          <p className='text-center already-account'>
            Already have an account?{' '}
            <Link to='/login' className='login-link hover:no-underline'>
              Login
            </Link>
          </p>
        </div>
        {/* <div className='data w-4/5 flex flex-col items-center justify-center bg-white rounded-lg mt-1 px-8'>
          
          <h2 className='form-heading'>Register</h2>
          <h5 className='sub-heading'>Join Us Today! Create Your Account</h5>
          <form
            className='w-full'
            // onSubmit={(e) => onSubmit(e)}
            method='POST'
          >
            <input type='hidden' name='remember' defaultValue='true' />
            <div className='mb-4 relative'>
              <img
                src={user_icon}
                alt='User Icon'
                className='absolute left-0 top-1/2 transform -translate-y-1/2 h-6 w-6 text-gray-500 input-icon-register'
                style={{ zIndex: 1 }}
              />
              <input
                id='name'
                name='name'
                type='text'
                // value={name}
                // onChange={(e) => onChange(e)}
                autoComplete='name'
                required
                placeholder='Full Name'
                className='register-input-field w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative pl-4'
              />
            </div>
            <div className='mb-4 relative'>
              <img
                src={email_icon}
                alt='User Icon'
                className='absolute left-0 top-1/2 transform -translate-y-1/2 h-6 w-6 text-gray-500 input-icon-register'
                style={{ zIndex: 1 }}
              />
              <input
                id='email-address'
                name='email'
                type='email'
                // value={email}
                // onChange={(e) => onChange(e)}
                autoComplete='email'
                required
                placeholder='Email'
                className='register-input-field w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative pl-4'
              />
            </div>
            <div className='mb-4 relative'>
              <img
                src={password_icon}
                alt='User Icon'
                className='absolute left-0 top-1/2 transform -translate-y-1/2 h-6 w-6 text-gray-500 input-icon-register'
                style={{ zIndex: 1 }}
              />
              <input
                id='password'
                name='password'
                type='password'
                // value={password}
                // onChange={(e) => onChange(e)}
                autoComplete='current-password'
                required
                placeholder='Password'
                className='password-input w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative pl-4'
              />
            </div>
            <div className='mb-4 relative'>
              <img
                src={password_icon}
                alt='User Icon'
                className='absolute left-0 top-1/2 transform -translate-y-1/2 h-6 w-6 text-gray-500 input-icon-register'
                style={{ zIndex: 1 }}
              />
              <input
                id='password2'
                name='password2'
                type='password'
                // value={password2}
                // onChange={(e) => onChange(e)}
                required
                placeholder='Confirm Password'
                className='password-input w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative pl-4'
              />
            </div>
            <div className='mb-4 relative'>
              <img
                src={phone_icon}
                alt='User Icon'
                className='absolute left-0 top-1/2 transform -translate-y-1/2 h-6 w-6 text-gray-500 input-icon-register'
                style={{ zIndex: 1 }}
              />
              <input
                id='mobile'
                name='mobile'
                type='tel'
                placeholder='Mobile Number'
                // value={mobile}
                // onChange={(e) => onChange(e)}
                autoComplete='current-mobile'
                pattern='[0-9]{10}'
                title='Please enter a 10-digit numeric mobile number.'
                inputMode='numeric'
                minLength={10}
                maxLength={10}
                required
                className='register-input-field w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative pl-4'
              />
            </div>
            <div className='mb-4 relative'>
              <img
                src={company_icon}
                alt='User Icon'
                className='absolute left-0 top-1/2 transform -translate-y-1/2 h-6 w-6 text-gray-500 input-icon-register'
                style={{ zIndex: 1 }}
              />
              <input
                id='company'
                name='company'
                type='text'
                placeholder='Company Name'
                // value={company}
                // onChange={(e) => onChange(e)}
                autoComplete='current-company'
                required
                className='register-input-field w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative pl-4'
              />
            </div>
            <button
              type='submit'
              className='register-button py-2 px-4 rounded-lg w-full mb-4 focus:ring-2 focus:ring-offset-2 focus:ring-button_ring-10 tracking-wider focus:outline-none'
            >
              Register
            </button>

            <div className='mb-2'>
              <label htmlFor='termsCheckbox' className='flex items-center'>
                <input
                  type='checkbox'
                  className='form-checkbox h-3 w-3 text-blue-500 mr-2'
                  id='termsCheckbox'
                />
                <span className='text-gray-700 terms-and-condition'>
                  By creating this account you accept our{' '}
                  <Link to={''} className='terms-and-condition-link'>
                    Terms and Conditions
                  </Link>
                </span>
              </label>
            </div>
          </form>

          
          <div className='flex items-center mb-2 w-full'>
            <hr className='flex-1 border-t border-gray-300' />
            <span className='mx-2 text-sm text-gray-500'>OR</span>
            <hr className='flex-1 border-t border-gray-300' />
          </div>

          <p className='text-center already-account'>
            Already have an account?{' '}
            <Link to='/login' className='login-link no-underline'>
              Login
            </Link>
          </p>
        </div> */}
      </div>

      {/* Right side */}
      <div className='flex-1 flex flex-col items-center justify-center right-side-login h-screen'>
        <h2 className='login-right-side-heading'>Welcome to Price Now</h2>
        <img
          src={bg_img_1}
          className='bg-img-1'
          alt='background-banner-login'
        />
        <h5 className='login-right-side-footer'>
          Maximize eCommerce revenue with Real-Time price alerts, analysis, and
          stock updates
        </h5>
      </div>
    </div>
  );
};

Register.propTypes = {
  // setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  // isAuthenticated: PropTypes.bool.isRequired,
  isOtpVerification: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  // isAuthenticated: state.auth.isAuthenticated,
  isOtpVerification: state.auth.isOtpVerification,
});

export default connect(mapStateToProps, { register })(Register);
