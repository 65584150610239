import axios from 'axios';
import { toast } from 'react-toastify';

import {
  PRICE_MONITORING_LIST_SUCCESS,
  PRICE_MONITORING_LIST_FAILED,
  PREMIUM_BASIC_DATA_SUCCESS,
  PREMIUM_BASIC_DATA_FAILED,
  PREMIUM_PROD_OUT_OF_STOCK_DATA_SUCCESS,
  PREMIUM_PROD_OUT_OF_STOCK_DATA_FAILED,
  PREMIUM_PROD_NEW_LAUNCHES_DATA_SUCCESS,
  PREMIUM_PROD_NEW_LAUNCHES_DATA_FAILED,
  PRICE_MONITORING_HISTORIC_DATA_SUCCESS,
  PRICE_MONITORING_HISTORIC_DATA_FAILED,
  SEARCH_VALUE,
} from './types';

// GET PRODUCTS LIST Free Plan
export const getPriceMonitoringData =
  (
    plan,
    industryFilterValue = null,
    formFactorFilterValue = null,
    brandFilterValue = null,
    platformFilterValue = null
  ) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    // const body = JSON.stringify({
    // });

    // console.log('formFactorFilterValue', formFactorFilterValue);

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URI}/price-now/api/price-monitoring-list`,
        {
          params: {
            plan: plan,
            industry: industryFilterValue,
            form_factor: formFactorFilterValue,
            platform: platformFilterValue,
            brand: brandFilterValue,
          },
          ...config,
        }
      );

      dispatch({
        type: PRICE_MONITORING_LIST_SUCCESS,
        payload: res.data,
      });

      // console.log('api res', res.data.distinctBrand);

      return res;
    } catch (err) {
      console.log('errors', err.response.data.errors);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => {
          // dispatch(setAlert(error.msg, 'danger'));
        });
      }

      dispatch({
        type: PRICE_MONITORING_LIST_FAILED,
      });
    }
  };

// GET USER INDUSTRY AND PLATFORMS LIST
export const getUserIndustryAndPlatform = () => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'Application/json',
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URI}/price-now/api/filter-industry`,
      {
        ...config,
      }
    );

    // dispatch({
    //   type: PRICE_MONITORING_LIST_SUCCESS,
    //   payload: res.data,
    // });

    // console.log('api res', res.data.distinctBrand);

    return res;
  } catch (err) {
    console.log('errors', err.response.data.errors);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        // dispatch(setAlert(error.msg, 'danger'));
      });
    }
  }
};

// GET USER INDUSTRY AND PLATFORMS LIST PREMIUM
export const getUserIndustryAndPlatformPrem =
  (industry) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URI}/price-now/api/industry-and-platform-premium`,
        {
          params: {
            industry,
          },
          ...config,
        }
      );

      return res;
    } catch (err) {
      console.log('errors', err.response.data.errors);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => {
          // dispatch(setAlert(error.msg, 'danger'));
        });
      }
    }
  };

// Save Subscription details (Industry, Category and Platform)
export const savePremiumSubDetails =
  (plan_type, duration, industryAndPlatformData, selectedplatform) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    const body = JSON.stringify({
      plan_type,
      duration: duration ? 'y' : 'm',
      industryAndPlatformData,
      selectedplatform,
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URI}/price-now/api/add-prem-sub`,
        body,
        config
      );

      // dispatch({
      //   type: OTP_VERIFIED,
      //   payload: res.data,
      // });

      // dispatch(loadUser());
      return res;
    } catch (err) {
      console.log('errors', err.response.data.errors);
      const errors = err.response.data.errors;

      // if (errors) {
      //   errors.forEach((error) => {
      //     // dispatch(setAlert(error.msg, 'danger'));
      //     dispatch(toast.error(error.msg));
      //   });
      // }

      // dispatch({
      //   type: OTP_FAILED,
      // });
    }
  };

// GET PREMIUM ANALYTIC DATA Basic
export const getPremiumAnalyticData =
  (
    plan,
    industryFilterValue,
    formFactorFilterValue = null,
    brandFilterValue = null,
    platformFilterValue = null
  ) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URI}/price-now/api/prem-basic-data`,
        {
          params: {
            plan_type: plan,
            industry: industryFilterValue,
            form_factor: formFactorFilterValue,
            platform: platformFilterValue,
            brand: brandFilterValue,
          },
          ...config,
        }
      );

      dispatch({
        type: PREMIUM_BASIC_DATA_SUCCESS,
        payload: res.data,
      });

      return res;
    } catch (err) {
      console.log('errors', err.response.data.errors);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => {
          // dispatch(setAlert(error.msg, 'danger'));
        });
      }

      dispatch({
        type: PREMIUM_BASIC_DATA_FAILED,
      });
    }
  };

// GET PREMIUM ANALYTIC DATA - PRODUCT OUT OF STOCK
export const getPremiumAnalyticDataProdOutOfStock =
  (
    plan,
    industryFilterValue,
    formFactorFilterValue = null,
    brandFilterValue = null,
    platformFilterValue = null,
    day
  ) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URI}/price-now/api/prem-prod-out-of-stock-data`,
        {
          params: {
            plan_type: plan,
            industry: industryFilterValue,
            form_factor: formFactorFilterValue,
            platform: platformFilterValue,
            brand: brandFilterValue,
            day,
          },
          ...config,
        }
      );

      dispatch({
        type: PREMIUM_PROD_OUT_OF_STOCK_DATA_SUCCESS,
        payload: res.data,
      });

      return res;
    } catch (err) {
      console.log('errors', err.response.data.errors);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => {
          // dispatch(setAlert(error.msg, 'danger'));
        });
      }

      dispatch({
        type: PREMIUM_PROD_OUT_OF_STOCK_DATA_FAILED,
      });
    }
  };

// GET PREMIUM ANALYTIC DATA - PRODUCTS NEW LAUNCHES
export const getPremiumAnalyticDataNewProdLaunch =
  (
    plan,
    industryFilterValue,
    formFactorFilterValue = null,
    brandFilterValue = null,
    platformFilterValue = null,
    day
  ) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URI}/price-now/api/prem-new-prod-data?`,
        {
          params: {
            plan_type: plan,
            industry: industryFilterValue,
            form_factor: formFactorFilterValue,
            platform: platformFilterValue,
            brand: brandFilterValue,
            day,
          },
          ...config,
        }
      );

      dispatch({
        type: PREMIUM_PROD_NEW_LAUNCHES_DATA_SUCCESS,
        payload: res.data,
      });

      return res;
    } catch (err) {
      console.log('errors', err.response.data.errors);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => {
          // dispatch(setAlert(error.msg, 'danger'));
        });
      }

      dispatch({
        type: PREMIUM_PROD_NEW_LAUNCHES_DATA_FAILED,
      });
    }
  };

// GET PRICE MONITORING - HISTORICAL SELLING PRICE
export const getPriceMonitoringHistoricalSellingPrice =
  (industry, productName) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URI}/price-now/api//price-monitoring-historic-data`,
        {
          params: {
            industry,
            productName,
          },
          ...config,
        }
      );

      dispatch({
        type: PRICE_MONITORING_HISTORIC_DATA_SUCCESS,
        payload: res.data,
      });

      return res;
    } catch (err) {
      console.log('errors', err.response.data.errors);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => {
          // dispatch(setAlert(error.msg, 'danger'));
        });
      }

      dispatch({
        type: PRICE_MONITORING_HISTORIC_DATA_FAILED,
      });
    }
  };
