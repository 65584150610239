import React, { useState, useEffect } from 'react';
import { Sidebar } from '../sidebar/Sidebar';
import Navbar from '../navbar/Navbar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/analytic/analytics.css';

// Actions
import {
  getPriceMonitoringData,
  getUserIndustryAndPlatform,
} from '../../actions/products';

// Components
import ReportCards from '../layout/cards/ReportCards';
import Loader from '../loader/Loader';
// import Loader2 from '../loader/Loader2';

// Images
import notepad_icon from '../../img/notepad_icon.png';
import clock_icon from '../../img/clock_icon.png';
import graph_up_icon from '../../img/graph_up_icon.png';
import graph_down_icon from '../../img/graph_down_icon.png';

import PriceMonitoringDataTable from '../layout/tables/PriceMonitoringDataTable';
import NoDataModal from '../modals/NoDataModal';

const Analytics = ({
  auth: { isAuthenticated, user },
  getPriceMonitoringData,
  getUserIndustryAndPlatform,
}) => {
  const [selectedProduct, setSelectedProduct] = useState('Price Monitoring');
  const [openFilters, setOpenFilters] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [apiLatestData, setApiLatestData] = useState('');
  const [data, setData] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshTime, setRefreshTime] = useState(null);
  const [priceHiked, setPriceHiked] = useState('');
  const [priceDropped, setPriceDropped] = useState('');

  let [industryFilterValue, setIndustryFilterValue] = useState(['Audio']);
  const [industryFilterList, setIndustryFilterList] = useState([]);
  let [platformFilterValue, setPlatformFilterValue] = useState(['Amazon']);
  const [platformFilterList, setPlatformFilterList] = useState([
    'Amazon',
    'Flipkart',
    'Myntra',
    'More',
  ]);
  let [formFactorFilterValue, setformFactorFilterValue] = useState([]);
  let [formFactorFilterList, setformFactorFilterList] = useState([]);
  let [brandFilterValue, setBrandFilterValue] = useState([]);
  let [brandFilterList, setBrandFilterList] = useState([]);
  const [modelFilterValue, setModelFilterValue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [onFirstLoad, setOnFirstLoad] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [skuCountRecords, setSkuCountRecords] = useState('');
  const [skuCount, setSkuCount] = useState('');

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
    // console.log('side bar toggle');
  };

  useEffect(() => {
    document.body.classList.add('with-background');

    return () => {
      document.body.classList.remove('with-background');
    };
  }, []);

  const handleProductClick = (productName) => {
    setSelectedProduct(productName);
    setOpenFilters([]);
  };

  // On Industry value change
  useEffect(() => {
    setIsLoading(true);
    setSidebarOpen(false);
    const fetchData = async () => {
      try {
        if (onFirstLoad) {
          setCurrentPage(1);
          // Call Action method
          const industryAndPlatform = await getUserIndustryAndPlatform(
            industryFilterValue
          );

          // Set industry filter list with capitalized industries
          const capitalizedIndustries =
            industryAndPlatform.data.filterValue.industry.map(
              (industry) =>
                industry.charAt(0).toUpperCase() +
                industry.slice(1).toLowerCase()
            );
          setIndustryFilterList(capitalizedIndustries);

          // Set industry filter list with capitalized industries
          const capitalizedPlatform =
            industryAndPlatform.data.filterValue.platform.map(
              (industry) =>
                industry.charAt(0).toUpperCase() +
                industry.slice(1).toLowerCase()
            );
          // setPlatformFilterList(capitalizedPlatform);

          // setPlatformFilterList(industryAndPlatform.data.filterValue.platform);
          // console.log('this is platofrm filter list', platformFilterList);

          // if (industryAndPlatform.data.platformNames.length === 1) {
          //   setPlatformFilterValue(industryAndPlatform.data.platformNames[0]);
          // } else {
          //   setPlatformFilterValue([]);
          // }

          if (selectedProduct === 'Price Monitoring') {
            // console.log('Hit Price Monitoring API');
            // onPriceMonitoring();
            onPriceMonitoringIndustryChange();
          }

          // if (selectedProduct === 'Price Monitoring') {
          //   const response = await getPremiumAnalyticData(
          //     user.plan_type,
          //     industryFilterValue,
          //     formFactorFilterValue,
          //     brandFilterValue,
          //     platformFilterValue
          //   );
          //   setApiData(response.data.records);
          //   setApiLatestData(response.data.latestData);
          //   setformFactorFilterList(response.data.distinctFormFactor);
          //   setBrandFilterList(response.data.distinctBrand);
          //   setIsLoading(false);
          //   setPlatformFilterValue([]);
          //   setformFactorFilterValue([]);
          //   setBrandFilterValue([]);
          //   setIsLoading(false);
          // }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Call the fetchData function initially

    // Add industryFilterValue as a dependency to trigger fetchData when it changes
  }, [industryFilterValue]);

  // On First time Loading
  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log('Product Change', selectedProduct);

        // Only execute the code if selectedProduct is 'Product Out of Stock'
        if (onFirstLoad) {
          // console.log('Price Monitoring First time loading');
          onPriceMonitoring();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [onFirstLoad]);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      if (selectedProduct === 'Price Monitoring') {
        const response = await getPriceMonitoringData(
          user.plan_type,
          industryFilterValue,
          formFactorFilterValue,
          brandFilterValue,
          platformFilterValue
        );
        // const data = await response.json();
        setApiData(response.data.records);
        setApiLatestData(response.data.latestData);
        setformFactorFilterList(response.data.distinctFormFactor);
        setBrandFilterList(response.data.distinctBrand);
        setSkuCountRecords(response.data.skuCountRecords);
        // console.log('apiLatestData', apiLatestData);
      }

      // Replace 'API_ENDPOINT' with your actual API endpoint
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const onLoad = async () => {
    try {
      setIsLoading(true);
      // console.log('Free Analytics');
      // Call Action method
      const industryAndPlatform = await getUserIndustryAndPlatform();

      // console.log(
      //   'Industry from loading',
      //   industryAndPlatform.data.filterValue
      // );

      // Set industry filter list with capitalized industries
      const capitalizedIndustries =
        industryAndPlatform.data.filterValue.industry.map(
          (industry) =>
            industry.charAt(0).toUpperCase() + industry.slice(1).toLowerCase()
        );
      setIndustryFilterList(capitalizedIndustries);

      // Set default value for industry filter value
      if (capitalizedIndustries.length > 0) {
        setIndustryFilterValue(capitalizedIndustries[0]);
      }

      setOnFirstLoad(true);
      // setPlatformFilterList(industryAndPlatform.data.filterValue.platform);

      // Set industry filter list with capitalized industries
      const capitalizedPlatform =
        industryAndPlatform.data.filterValue.platform.map(
          (industry) =>
            industry.charAt(0).toUpperCase() + industry.slice(1).toLowerCase()
        );
      // setPlatformFilterList(capitalizedPlatform);

      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const onResetFilter = async () => {
    try {
      setIsLoading(true);
      setCurrentPage(1);
      // Call Action method
      const industryAndPlatform = await getUserIndustryAndPlatform();
      // Set industry filter list with capitalized industries
      const capitalizedIndustries =
        industryAndPlatform.data.filterValue.industry.map(
          (industry) =>
            industry.charAt(0).toUpperCase() + industry.slice(1).toLowerCase()
        );
      setIndustryFilterList(capitalizedIndustries);

      // Set default value for industry filter value
      if (capitalizedIndustries.length > 0) {
        setIndustryFilterValue(capitalizedIndustries[0]);
      }

      // Set industry filter list with capitalized industries
      const capitalizedPlatform =
        industryAndPlatform.data.filterValue.platform.map(
          (industry) =>
            industry.charAt(0).toUpperCase() + industry.slice(1).toLowerCase()
        );
      // setPlatformFilterList(capitalizedPlatform);
      setformFactorFilterValue([]);
      setBrandFilterValue([]);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (user) {
      // console.log('user.plan_type', user.plan_type);
    }
    onLoad(); // Chnage with one time function to get info like, user, industry, platform and related Form factor and Brand name
  }, [user]);

  // Ftech price monitoring data
  const onPriceMonitoring = async () => {
    setIsLoading(true);
    try {
      // console.log('Price Monitoring Data');
      setCurrentPage(1);
      const response = await getPriceMonitoringData(
        user.plan_type,
        industryFilterValue,
        formFactorFilterValue,
        brandFilterValue,
        platformFilterValue
      );
      // Set Variable values, set API Data, set API Latest Data, Set
      // console.log('reposne dada', industryAndPlatform.data);
      setApiData(response.data.records);
      setApiLatestData(response.data.latestData);

      setformFactorFilterList(response.data.distinctFormFactor);
      setBrandFilterList(response.data.distinctBrand);
      setSkuCountRecords(response.data.skuCountRecords);
      // console.log('test 1', industryAndPlatform.data.filterValue.platform);

      if (!onFirstLoad) setOnFirstLoad(true);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const onPriceMonitoringIndustryChange = async () => {
    setIsLoading(true);
    try {
      // console.log('Price Monitoring Data');
      setCurrentPage(1);
      const response = await getPriceMonitoringData(
        user.plan_type,
        industryFilterValue,
        (formFactorFilterValue = []),
        (brandFilterValue = []),
        (platformFilterValue = ['Amazon'])
      );
      // Set Variable values, set API Data, set API Latest Data, Set
      // console.log('reposne dada', industryAndPlatform.data);
      setApiData(response.data.records);
      setApiLatestData(response.data.latestData);

      setformFactorFilterList(response.data.distinctFormFactor);
      setBrandFilterList(response.data.distinctBrand);
      setSkuCountRecords(response.data.skuCountRecords);
      // console.log('test 1', industryAndPlatform.data.filterValue.platform);

      if (!onFirstLoad) setOnFirstLoad(true);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  if (!data) {
    // console.log('apiData 11', formFactorFilterValue);
    // console.log('apiData 12', platformFilterValue);
    // console.log('apiData 13', data);
    // console.log('apiData 14', brandFilterValue);
  }

  const onFilterApply = async () => {
    // console.log('onFIlter Apply called');
    setCurrentPage(1);

    try {
      fetchData();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const onFilterReset = async () => {
    // console.log('onFIlter reset called');
    setCurrentPage(1);
    try {
      // Replace 'API_ENDPOINT' with your actual API endpoint
      // setformFactorFilterValue([]);
      // console.log('formFactorFilterValue', formFactorFilterValue);
      // onLoad();
      onResetFilter();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getPaginatedItems = (items) => {
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    return items.slice(startIndex, endIndex);
  };

  const handleNextPageClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const totalAmazonItems = apiData ? apiData.length : 0;
  const totalPages = Math.ceil(totalAmazonItems / 10);

  // console.log('selected Prod', selectedProduct);
  // console.log('selected Prod', industryFilterValue);
  // console.log('all prod', apiData[0].Date);

  // Assuming this useEffect hook is inside your component
  useEffect(() => {
    if (apiData.length > 0) {
      const latestObject = apiData.reduce((latest, current) => {
        const latestTime = new Date(`1970-01-01T${latest.Time}Z`);
        const currentTime = new Date(`1970-01-01T${current.Time}Z`);
        return currentTime > latestTime ? current : latest;
      }, apiData[0]);

      // Count Price Hiked and Price Dropped
      const priceHikedCount = apiData.filter(
        (item) => item.Alert === 'Price Hiked'
      ).length;
      const priceDroppedCount = apiData.filter(
        (item) => item.Alert === 'Price Dropped'
      ).length;

      // Update state only once inside useEffect
      setRefreshTime(latestObject.Time);
      setPriceHiked(priceHikedCount);
      setPriceDropped(priceDroppedCount);

      // Log the counts or use them as needed
      // console.log('SKU COunt:', apiData.);
      // console.log('Price Dropped Count:', priceDroppedCount);
    }
  }, [apiData]);

  useEffect(() => {
    if (skuCountRecords.length > 0) {
      const totalSkuCount = skuCountRecords.reduce(
        (sum, record) => sum + record.sku_count,
        0
      );
      // console.log('Total SKU Count:', totalSkuCount);
      setSkuCount(totalSkuCount);
      // You can store the totalSkuCount in state or use it as needed
    }
  }, [skuCountRecords]);

  const latestObject = apiData.reduce((latest, current) => {
    // Compare the time strings
    const latestTime = new Date(`1970-01-01T${latest.Time}Z`);
    const currentTime = new Date(`1970-01-01T${current.Time}Z`);

    // Update if the current object has a later time
    return currentTime > latestTime ? current : latest;
  }, apiData[0]);

  // useEffect(() => {
  //   console.log('SKU COUNT ', skuCount);
  // }, [skuCount]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='flex'>
          {industryFilterList &&
          platformFilterList &&
          brandFilterList &&
          formFactorFilterList ? (
            <div className='sidebar-hidden'>
              <Sidebar
                setSidebarOpen={setSidebarOpen}
                industryFilterList={industryFilterList}
                platformFilterList={platformFilterList}
                formFactorFilterList={formFactorFilterList}
                brandFilterList={brandFilterList}
                industryFilterValue={industryFilterValue}
                setIndustryFilterValue={setIndustryFilterValue}
                platformFilterValue={platformFilterValue}
                setPlatformFilterValue={setPlatformFilterValue}
                brandFilterValue={brandFilterValue}
                setBrandFilterValue={setBrandFilterValue}
                formFactorFilterValue={formFactorFilterValue}
                setformFactorFilterValue={setformFactorFilterValue}
                onFilterApply={onFilterApply}
                onFilterReset={onFilterReset}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
              />
            </div>
          ) : (
            ''
          )}

          {industryFilterList &&
          platformFilterList &&
          brandFilterList &&
          formFactorFilterList &&
          sidebarOpen ? (
            <Sidebar
              setSidebarOpen={setSidebarOpen}
              industryFilterList={industryFilterList}
              platformFilterList={platformFilterList}
              formFactorFilterList={formFactorFilterList}
              brandFilterList={brandFilterList}
              industryFilterValue={industryFilterValue}
              setIndustryFilterValue={setIndustryFilterValue}
              platformFilterValue={platformFilterValue}
              setPlatformFilterValue={setPlatformFilterValue}
              brandFilterValue={brandFilterValue}
              setBrandFilterValue={setBrandFilterValue}
              formFactorFilterValue={formFactorFilterValue}
              setformFactorFilterValue={setformFactorFilterValue}
              onFilterApply={onFilterApply}
              onFilterReset={onFilterReset}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
            />
          ) : null}

          <div className='flex flex-col w-full analytics-page-1 h-full'>
            <Navbar handleSidebarToggle={handleSidebarToggle} />

            <div className='report-card-component-wrapper flex flex-wrap mb-8'>
              {apiData.length > 0 && (
                <ReportCards
                  icon={notepad_icon}
                  card_type={'Report date'}
                  card_data={apiData[0].Date}
                  bgColor={'#BB9BFC'}
                />
              )}
              {apiData.length > 0 && (
                <ReportCards
                  icon={clock_icon}
                  card_type={'Refresh Time'}
                  card_data={refreshTime + ' UTC'}
                  bgColor={'#F97D3A'}
                />
              )}
              {apiData.length > 0 && (
                <ReportCards
                  icon={graph_up_icon}
                  card_type={'Number of Price Hike'}
                  card_data={priceHiked}
                  bgColor={'#00D6AF'}
                />
              )}
              {apiData.length > 0 && (
                <ReportCards
                  icon={graph_down_icon}
                  card_type={'Number of Price Dropped'}
                  card_data={priceDropped}
                  bgColor={'#D6B100'}
                />
              )}

              {skuCount && (
                <ReportCards
                  icon={graph_down_icon}
                  card_type={'SKU Count'}
                  card_data={skuCount}
                  bgColor={'#4793AF'}
                />
              )}
            </div>

            <div>
              {apiLatestData === 0 && (
                <div className='note flex justify-center mx-2'>
                  <span>
                    Today's price change data will be updated shortly.
                  </span>
                </div>
              )}
              {/* Conditional rendering of NoDataModal */}

              {/* Conditional rendering of NoDataModal */}
              {data === false && data !== null && data !== undefined && (
                <NoDataModal />
              )}
            </div>

            <div className='table-container'>
              <PriceMonitoringDataTable
                data={apiData}
                getPaginatedItems={getPaginatedItems}
                setData={setApiData}
              />
            </div>
            <div className='w-full flex justify-center pt-1 pb-5 mt-3'>
              <div className='bg-white text-black items-center flex justify-between rounded-full py-1 shadow-md'>
                <button
                  className='prev-button py-1 px-2 focus:outline-none'
                  disabled={currentPage === 1}
                  onClick={handlePrevPage}
                >
                  <i class='fa-solid fa-chevron-left pr-1 pl-1'></i>PREV
                </button>
                <p className='page-count'>
                  {currentPage} | {totalPages}
                </p>
                <button
                  className=' next-button py-1 px-2 focus:outline-none'
                  onClick={handleNextPageClick}
                  disabled={currentPage === totalPages}
                >
                  NEXT<i class='fa-solid fa-chevron-right pl-1'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Analytics.propTypes = {
  getPriceMonitoringData: PropTypes.func.isRequired,
  getUserIndustryAndPlatform: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getPriceMonitoringData,
  getUserIndustryAndPlatform,
})(Analytics);
