import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../loader/Loader';
import defaultProfilePic from '../../img/defaultProfilePic.png';
import { updateProfile, getUser } from '../../actions/auth';
import ProfilePic from '../layout/profile/ProfilePic';
import Navbar from '../navbar/Navbar';
import axios from 'axios';

// CSS
import '../../css/userDetails/editprofile.css';

let MyProfile = ({
  auth: { isAuthenticated, user },
  updateProfile,
  getUser,
}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile_number: '',
    company_name: '',
    password: '',
    confirm_password: '',
  });

  const [profileData, setProfiledata] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState('');
  const [loading, setLoading] = useState(false);
  const [fileSizeExceedsLimit, setFileSizeExceedsLimit] = useState(false);

  useEffect(() => {
    document.body.classList.add('with-background');

    return () => {
      document.body.classList.remove('with-background');
    };
  }, []);

  const {
    name,
    email,
    mobile_number,
    password,
    confirm_password,
    company_name,
  } = formData;

  const handleImageInputChange = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      if (imageFile.size > 50 * 1024) {
        setFileSizeExceedsLimit(true);
        toast.warn('Image size should be 50KB or less.');
      } else {
        setFileSizeExceedsLimit(false);
        setSelectedImage(imageFile);
      }
    }
  };

  const allowedImageTypes = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/heif',
    'image/heic',
    'image/svg+xml',
  ];

  const handleSave = async () => {
    try {
      if (!selectedImage) {
        alert('Please select at least one image to upload.');
        return;
      }

      if (!allowedImageTypes.includes(selectedImage.type)) {
        toast.error(
          'Please select a valid image file (jpg, jpeg, png, heif, heic, or svg).'
        );
        return;
      }

      setUploading(true);
      toast.info('Image uploading...');

      const formData = new FormData();
      formData.append('image', selectedImage);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}/price-now/api/upload-profile-img`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      if (response.status === 200) {
        setIsModalOpen(false);
        toast.success('Image Uploaded');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        console.error('Error uploading images:', response.data);
        toast.error('Error Uploading Image');
      }
    } catch (error) {
      console.error('Error uploading images:', error);
      toast.error('Error Uploading Image');
    } finally {
      setUploading(false);
      setTimeout(() => {
        setNotification('');
      }, 3000);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    // console.log('isModalOpen', isModalOpen);
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getUser().then((res) => {
      setFormData({
        ...formData,
        name: res.data.user_name,
        email: res.data.user_email,
        mobile_number: res.data.user_mobile_number,
        company_name: res.data.user_company,
      });
      setProfiledata(true);
    });
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirm_password) {
      // setAlert('Password does not match', 'danger');
      toast.error('Confirm password does not matches!');
      // console.log('Password does not matches');
    } else {
      toast.info('Saving Changes...');
      updateProfile({ name, email, password, mobile_number, company_name })
        .then((res) => {
          // setLoading(false);
          // console.log('API return', res);
          if (res.status === 200) {
            toast.success('Profile Updated Successfully');
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        })
        .catch(() => {});
    }
  };

  const profilePicClassName =
    'profile-pic-placeholder rounded-full p-1 ring-2 ring-gray-300 dark:ring-gray-500';

  return (
    <>
      {profileData ? (
        <div className='user-profile-page-div h-auto'>
          <Navbar />
          <div className='user-profile-main-content-div flex flex-col p-2 bg-white rounded-xl shadow-xl mb-4'>
            <div className='flex justify-start w-full items-center pt-0 mb-6'>
              <Link to='/profile' className='profile-back-home'>
                <i className='fa-solid fa-chevron-left pr-2 text-xs'></i>Back
              </Link>
            </div>
            <div className='profile-photo flex justify-center flex-col mb-12'>
              <ProfilePic
                defaultProfilePic={defaultProfilePic}
                className={profilePicClassName}
              />
              <p
                className='update-profile-pic justify-center flex'
                onClick={toggleModal}
              >
                Update profile picture
              </p>
              {isModalOpen && (
                <div
                  className={`profile-modal-container ${
                    isModalOpen ? 'profile-modal-open' : ''
                  }`}
                >
                  <div className='profile-modal rounded-xl'>
                    <div className='flex justify-end mb-2'>
                      <i
                        className='fa-solid fa-circle-xmark profile-modal-close-icon'
                        onClick={toggleModal}
                      ></i>
                    </div>
                    <div
                      className='modal-content border-dashed'
                      style={{
                        width: '100%',
                        maxWidth: '744px',
                        height: '243px',
                      }}
                    >
                      <div className='border-dashed flex flex-col justify-center items-center'>
                        <i className='fas fa-cloud-upload-alt profile-upload-icon'></i>
                        <p className='profile-upload-text'>
                          Click the button to select and upload a picture
                        </p>
                        <input
                          type='file'
                          accept='image/*'
                          onChange={handleImageInputChange}
                          className='profile-select-button'
                        />
                        <button
                          className='profile-upload-button focus:ring-2 focus:outline-none focus:ring-button_ring-10 rounded-md w-full sm:w-auto px-4 py-1.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 focus:ring-offset-2'
                          onClick={handleSave}
                          disabled={fileSizeExceedsLimit}
                        >
                          Upload Image
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className='input-fields-div flex justify-center'>
              <form onSubmit={(e) => onSubmit(e)} method='POST'>
                <div className='grid gap-6 mb-4 md:grid-cols-2'>
                  <div>
                    <input
                      type='text'
                      id='name'
                      name='name'
                      className='bg-gray-50 border profile-input text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                      value={name}
                      maxLength='40'
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                  <div>
                    <input
                      type='text'
                      id='company_name'
                      name='company_name'
                      maxLength='40'
                      className='bg-gray-50 border profile-input text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                      value={
                        company_name != null
                          ? company_name
                          : user
                          ? user.company_name
                          : ''
                      }
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                  <div>
                    <input
                      type='tel'
                      id='mobile_number'
                      name='mobile_number'
                      pattern='[0-9]*'
                      minLength='10'
                      maxLength='10'
                      onKeyDown={(e) => {
                        if (
                          !/^\d$/.test(e.key) &&
                          e.key !== 'Backspace' &&
                          e.key !== 'Delete'
                        ) {
                          e.preventDefault();
                        }
                      }}
                      className='bg-gray-50 border profile-input text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                      value={
                        mobile_number != null
                          ? mobile_number
                          : user
                          ? user.mobile_number
                          : ''
                      }
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>

                  <div>
                    <input
                      type='email'
                      id='email'
                      name='email'
                      readOnly
                      className='bg-gray-50 border profile-input-email text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                      value={email != null ? email : user ? user.email : ''}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                  <div>
                    <input
                      type='password'
                      id='password'
                      name='password'
                      className='profile-password border bg-gray-50 profile-input text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                      value={password}
                      onChange={(e) => onChange(e)}
                      placeholder='Enter New Password'
                      minLength='6'
                    />
                  </div>
                  <div>
                    <input
                      type='password'
                      id='confirm_password'
                      name='confirm_password'
                      className='profile-password border bg-gray-50 profile-input text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                      value={confirm_password}
                      onChange={(e) => onChange(e)}
                      placeholder='Confirm New Password'
                      minLength='6'
                    />
                  </div>
                </div>
                <div className='flex justify-center gap-3'>
                  <button
                    type='submit'
                    className='profile-button-save focus:ring-2 focus:outline-none focus:ring-button_ring-10 rounded-md w-full sm:w-auto px-4 py-2 text-center focus:ring-offset-2'
                  >
                    Save Changes
                  </button>
                  <Link to='/profile'>
                    <button
                      type='button'
                      className='profile-button-cancel focus:ring-2 focus:outline-none focus:ring-button_ring-20 rounded-md w-full sm:w-auto px-4 py-2 text-center focus:ring-offset-2'
                    >
                      Cancel
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps, { updateProfile, getUser })(MyProfile);
