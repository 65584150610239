import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  TimeScale,
  Filler,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// CSS Import
import '../../css/chartsAndGraphs/sellingPriceGraph.css';

// Actions
import { getPriceMonitoringHistoricalSellingPrice } from '../../actions/products';
// import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  TimeScale,
  Tooltip,
  Filler
);

const SellingPriceGraph = ({
  productName,
  industry,
  getPriceMonitoringHistoricalSellingPrice,
}) => {
  // Example static data for the chart
  // const historicData = [
  //   { date: '2024-01-01', price: 100 },
  //   { date: '2024-01-02', price: 110 },
  //   { date: '2024-01-03', price: 105 },
  //   { date: '2024-01-04', price: 115 },
  //   { date: '2024-01-05', price: 120 },
  //   { date: '2024-01-06', price: 118 },
  //   { date: '2024-01-07', price: 125 },
  // ];

  const [historicData, setHistoricData] = useState([]);
  const [averagePrice, setAveragePrice] = useState(null);
  const [highestPrice, setHighestPrice] = useState(null);
  const [lowestPrice, setLowestPrice] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getPriceMonitoringHistoricalSellingPrice(
          industry,
          productName
        );

        // Sort date by date
        const sortedData = res.data.sellingPriceHistoricData.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        setHistoricData(sortedData);

        // Calculate average, highest, and lowest prices
        const prices = sortedData.map((dataPoint) => dataPoint.price);
        const sum = prices.reduce((acc, curr) => acc + curr, 0);
        const avg = sum / prices.length;
        const max = Math.max(...prices);
        const min = Math.min(...prices);

        setAveragePrice(Math.floor(avg.toFixed(2))); // round off to 4 decimal places
        setHighestPrice(max);
        setLowestPrice(min);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [productName]);
  if (historicData) {
    // console.log('historic data', historicData);
    // console.log('historic data', typeof historicData[0].date);
  }

  // Prepare data for the chart
  const data = {
    // labels: historicData.map((dataPoint) => new Date(dataPoint.date)), // Convert date strings to Date objects
    labels: historicData.map((dataPoint) => dataPoint.date),
    datasets: [
      {
        label: 'Selling Price',
        data: historicData.map((dataPoint) => ({
          x: dataPoint.date,
          y: dataPoint.price,
        })),
        fill: {
          target: 'origin', // Fill from the bottom of the chart
          above: 'rgba(196, 195, 245, 0.34)', // Top color with 34% transparency
          below: 'rgba(51, 48, 228, 00)', // Bottom color with 100% transparency
          gradient: {
            orientation: 'vertical', // Gradient orientation from top to bottom
          },
        },
        borderColor: '#3330E4',
        tension: 0.4,
        pointStyle: 'circle',
        pointRadius: 3,
        pointHoverRadius: 5,
      },
    ],
  };

  // Define chart options
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'MMM dd, yyyy', // Format for the tooltip
          displayFormats: {
            day: 'MMM dd', // Format for the x-axis labels
          },
        },
        title: {
          display: true,
          text: 'Date',
        },
        grid: {
          display: true,
        },
        ticks: {
          font: {
            size: 10, // Set the font size for the x-axis labels
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Selling Price',
        },
        grid: {
          display: true,
        },
        ticks: {
          font: {
            size: 10, // Set the font size for the x-axis labels
          },
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Last 30 Days Price Change',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const date = context.label;
            const price = context.parsed.y;
            return `Price: ${price}`;
          },
        },
      },
    },
  };

  return (
    <div
      className='flex gap-4 mb-2'
      style={{
        // width: '961px',
        height: '191px',
        // display: isOpen ? 'block' : 'none',
      }}
    >
      {/* Left side */}
      <div className='flex-1 ml-3 graph-wrapper-div'>
        <div style={{ width: '100%', height: '100%' }}>
          <Line data={data} options={options} />
        </div>
      </div>

      {/* Right side */}
      <div className='right-side-detail-div mr-3'>
        <div className='my-4 ml-2 border-l-2 highest-price-div pl-2'>
          <h6 className='selling-price-details'>Highest Selling Price</h6>
          <p className='selling-price-sub-details'>{highestPrice}</p>
        </div>
        <div className='mb-4 ml-2 border-l-2 average-price-div pl-2'>
          <h3 className='selling-price-details'>Average Selling Price</h3>
          <p className='selling-price-sub-details'>{averagePrice}</p>
        </div>
        <div className='border-l-2 ml-2 lowest-price-div pl-2'>
          <h3 className='selling-price-details'>Lowest Selling Price</h3>
          <p className='selling-price-sub-details'>{lowestPrice}</p>
        </div>
      </div>
    </div>
  );
};

SellingPriceGraph.propTypes = {
  getPriceMonitoringHistoricalSellingPrice: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getPriceMonitoringHistoricalSellingPrice,
})(SellingPriceGraph);
