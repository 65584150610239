// src/utils/csvUtils.js
export const convertToCSV = (data, fields) => {
  const csvRows = [];

  // Add the header row
  const headers = fields.map((field) => field.label).join(',');
  csvRows.push(headers);

  // Add the data rows
  for (const item of data) {
    const row = fields
      .map((field) => {
        let value = item[field.key];
        if (field.key === 'First_Available_Date' && value) {
          value = `'${value}`; // Prefix date with a single quote
        }
        return `"${value}"`;
      })
      .join(',');
    csvRows.push(row);
  }

  return csvRows.join('\n');
};

export const downloadFullReport = (data, fields, filename) => {
  const csvData = convertToCSV(data, fields);
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);

  // const currentDate = new Date().toISOString().split('T')[0].replace(/-/g, '');
  // const fileName = `PriceMonitoring_Alert_Report_${industry}_${currentDate}.csv`;
  // const fileName = `PriceMonitoring_Alert_Report_${industry}_${currentDate}.csv`;

  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  a.click();
};
