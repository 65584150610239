import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Images
import white_logo from '../../img/white_logo.png';
import product_name_filter_icon from '../../img/product_name_filter_icon.png';
import prod_out_of_stock_icon from '../../img/prod_out_of_stock_icon.png';
import new_prod_launches_icon from '../../img/new_prod_launches_icon.png';

// CSS
import '../../css/sidebar/sidebar.css';

export const Sidebar = ({
  setSidebarOpen,
  industryFilterList,
  platformFilterList,
  formFactorFilterList,
  brandFilterList,
  industryFilterValue,
  setIndustryFilterValue,
  platformFilterValue,
  setPlatformFilterValue,
  brandFilterValue,
  setBrandFilterValue,
  formFactorFilterValue,
  setformFactorFilterValue,
  onFilterApply,
  onFilterReset,
  selectedProduct,
  setSelectedProduct,
}) => {
  const [openFilters, setOpenFilters] = useState({});
  // const [industryFilterValue, setIndustryFilterValue] = useState([]);
  // const [platformFilterValue, setPlatformFilterValue] = useState([]);
  // const [formFactorFilterValue, setformFactorFilterValue] = useState([]);
  // const [brandFilterValue, setBrandFilterValue] = useState([]);
  const [modelFilterValue, setModelFilterValue] = useState([]);
  const [doneClicked, setDoneClicked] = useState(false);
  const [resetClicked, setResetClicked] = useState(false);
  const [planType, setPlanType] = useState('basic');

  // console.log('Free Sidebar');
  // console.log('Fres Sidebar Platoform', platformFilterList);

  // Updated product list to disable the last two products
  const products = [
    'Price Monitoring',
    'Product Out of Stock',
    'New Product Launches',
  ].map((product, index) => {
    // Disable the last two products
    const isDisabled = index >= 1;

    return {
      name: product,
      isDisabled,
    };
  });

  const filterOptions = {
    'Price Monitoring': {
      Industry: industryFilterList,
      Platform: platformFilterList,
      'Form Factor': formFactorFilterList,
      Brand: brandFilterList,
      // Model: ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5'],
    },
    'Product Out of Stock': {
      Industry: industryFilterList,
      Platform: platformFilterList,
      'Form Factor': formFactorFilterList,
      Brand: brandFilterList,
    },
    'New Product Launches': {
      Industry: industryFilterList,
      Platform: platformFilterList,
      'Form Factor': formFactorFilterList,
      Brand: brandFilterList,
    },
  };

  const handleProductClick = (productName) => {
    // console.log('new product selected', productName);
    setSelectedProduct(productName);
    setOpenFilters({});
    // setIndustryFilterValue([]);
    // setPlatformFilterValue([]);
    // setformFactorFilterValue([]);
    // setBrandFilterValue([]);

    // Check if the product is 'Price Monitoring' and planType is 'basic' or 'free'
    // if (
    //   productName === 'Price Monitoring' &&
    //   (planType === 'basic' || planType === 'free')
    // ) {
    //   setIndustryFilterValue(['Audio']);
    // }
  };

  const toggleFilter = (filter) => {
    setOpenFilters((prevOpenFilters) => ({
      ...prevOpenFilters,
      [filter]: !prevOpenFilters[filter],
    }));
  };

  const handleCheckboxChange = (filter, option) => {
    // Update the corresponding state variable based on the filter
    switch (filter) {
      case 'Platform':
        setPlatformFilterValue((prevValue) =>
          prevValue.includes(option)
            ? prevValue.filter((value) => value !== option)
            : [...prevValue, option]
        );

        break;
      case 'Form Factor':
        setformFactorFilterValue((prevValue) =>
          prevValue.includes(option)
            ? prevValue.filter((value) => value !== option)
            : [...prevValue, option]
        );

        break;
      case 'Brand':
        setBrandFilterValue((prevValue) =>
          prevValue.includes(option)
            ? prevValue.filter((value) => value !== option)
            : [...prevValue, option]
        );

        break;
      case 'Model':
        setModelFilterValue((prevValue) =>
          prevValue.includes(option)
            ? prevValue.filter((value) => value !== option)
            : [...prevValue, option]
        );
        // ... (similar cases for other filters)
        break;
      default:
        break;
    }
  };

  const handleDone = () => {
    // Set the state to trigger the click effect
    setDoneClicked(true);
    setSidebarOpen(false);

    // Reset the state after a short delay (e.g., 300 milliseconds)
    setTimeout(() => {
      setDoneClicked(false);
    }, 300);

    // console.log('Selected Product:', selectedProduct);
    // console.log('Open Filters:', openFilters);
    // console.log('Industry Filter Value:', industryFilterValue);
    // console.log('Platform Filter Value:', platformFilterValue);
    // console.log('Form Factor Filter Value:', formFactorFilterValue);
    // console.log('Branad Filter Value:', brandFilterValue);
    // console.log('Model Filter Value:', modelFilterValue);
    onFilterApply();
  };

  const handleReset = () => {
    // Set the state to trigger the click effect
    setResetClicked(true);
    setSidebarOpen(false);

    // Reset the state after a short delay (e.g., 300 milliseconds)
    setTimeout(() => {
      setResetClicked(false);
    }, 300);

    // console.log('Before state update');
    setOpenFilters({});
    planType === 'basic'
      ? setIndustryFilterValue(['Audio'])
      : setIndustryFilterValue(['']);
    setPlatformFilterValue(['Amazon']);
    setformFactorFilterValue([]);
    setBrandFilterValue([]);
    // console.log('After state update');
    onFilterReset();
  };

  // Map each product name to its corresponding icon
  const productIcons = {
    'Price Monitoring': product_name_filter_icon,
    'Product Out of Stock': prod_out_of_stock_icon,
    'New Product Launches': new_prod_launches_icon,
    // ... add more mappings for other products
  };

  return (
    <div className='fixed h-screen sidebar-area text-white w-64 p-4 overflow-y-auto max-h-screen z-50'>
      {/* Logo */}
      <div className='flex justify-left items-center mb-4'>
        <Link to='/homepage' className='hover:no-underline'>
          <img src={white_logo} alt='price_now_logo' className='sidebar-logo' />
        </Link>
      </div>

      {/* Filters */}
      <div className='mb-2 text-reports'>Reports</div>
      <hr className='bg-white' />

      {/* Products */}
      <div className='mt-4'>
        {products.map((product) => (
          <div
            key={product.name}
            className={`cursor-pointer mb-2 flex justify-between items-center ${
              selectedProduct === product.name
                ? 'selected-product-report'
                : 'unselected-product-report'
            } ${product.isDisabled ? 'disabled-product-report' : ''}`}
            onClick={() =>
              !product.isDisabled && handleProductClick(product.name)
            }
          >
            <div className='flex items-center w-full'>
              <img
                src={productIcons[product.name]}
                alt={`Icon for ${product.name}`}
                className={`mr-2 product-side-icon ${
                  product.isDisabled ? 'disabled-icon' : ''
                }`}
              />
              {selectedProduct === product.name ? (
                <div className='flex justify-between items-center w-full'>
                  <span className='sidebar-prod-name'>{product.name}</span>{' '}
                  <span>
                    <i className='fa-solid fa-check'></i>
                  </span>
                </div>
              ) : (
                product.name
              )}
            </div>
          </div>
        ))}
      </div>

      <div className='text-filters mb-2 mt-4'>Filters</div>
      <hr className='bg-white' />

      {/* Filters for the selected product */}
      <div className='mt-4'>
        {/* Conditionally render filters based on the selected product */}
        {Object.keys(filterOptions[selectedProduct]).map((filter) => (
          <div key={filter} className='mb-2'>
            <div className='flex items-center justify-between mb-1 filter-dropdown-text'>
              {filter}
              <button
                className='ml-2 text-white focus:outline-none'
                onClick={() => toggleFilter(filter)}
              >
                {openFilters[filter] ? (
                  <i class='fa-solid fa-chevron-up'></i>
                ) : (
                  <i class='fa-solid fa-chevron-down'></i>
                )}
              </button>
            </div>
            {/* Data for filter options */}
            {openFilters[filter] && (
              <div className='filter-bg p-2 rounded-md filter-dropdown-text'>
                {filter === 'Industry'
                  ? // Render radio buttons for Industry filter
                    filterOptions[selectedProduct][filter].map((option) => (
                      <label key={option} className='flex items-center mb-0'>
                        <input
                          type='radio'
                          className='mr-2'
                          onChange={() => setIndustryFilterValue(option)}
                          checked={industryFilterValue === option}
                        />
                        <span className='text-center'>
                          {option.replace(/_/g, ' ')}
                        </span>
                      </label>
                    ))
                  : // Render checkboxes for other filters
                    filterOptions[selectedProduct][filter].map((option) => (
                      <label key={option} className='flex items-center mb-0'>
                        <input
                          type='checkbox'
                          className='mr-2'
                          onChange={() => handleCheckboxChange(filter, option)}
                          checked={
                            filter === 'Platform'
                              ? platformFilterValue.includes(option)
                              : filter === 'Form Factor'
                              ? formFactorFilterValue.includes(option)
                              : filter === 'Brand'
                              ? brandFilterValue.includes(option)
                              : filter === 'Model'
                              ? modelFilterValue.includes(option)
                              : false
                          }
                          disabled={filter === 'Platform'} // Disable all platform checkboxes
                          // disabled={
                          //   selectedProduct === 'Price Monitoring' &&
                          //   (planType === 'basic' || planType === 'free') &&
                          //   filter === 'Platform' &&
                          //   (option === 'Audio' ||
                          //     filterOptions['Price Monitoring'][
                          //       'Platform'
                          //     ].includes(option))
                          // }
                        />
                        <span className='text-center'>{option}</span>
                      </label>
                    ))}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Done and Reset buttons */}
      <div className='mt-4'>
        <button
          className={`button-done border border-white py-1 mb-2 w-full focus:outline-none rounded-md filter-done-button ${
            doneClicked ? 'clicked' : ''
          }`}
          onClick={handleDone}
        >
          Done
        </button>
        <button
          className={`button-reset border border-white py-1 w-full focus:outline-none rounded-md ${
            resetClicked ? 'clicked' : ''
          }`}
          onClick={handleReset}
        >
          Reset
        </button>
      </div>
    </div>
  );
};
