import React, { useState, useEffect } from 'react';
import { SidebarPremium } from '../sidebar/SidebarPremium';
import Navbar from '../navbar/Navbar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/analytic/analytics.css';
import '../../css/analytic/analyticsPremimum.css';

// Actions
import {
  getPriceMonitoringData,
  getUserIndustryAndPlatform,
  getUserIndustryAndPlatformPrem,
  getPremiumAnalyticData,
  getPremiumAnalyticDataProdOutOfStock,
  getPremiumAnalyticDataNewProdLaunch,
} from '../../actions/products';

// Components
import ReportCards from '../layout/cards/ReportCards';
import Loader from '../loader/Loader';
// import Loader2 from '../loader/Loader2';
import PriceMonitoringDataTable from '../layout/tables/PriceMonitoringDataTable';
import ProductOutOfStockPremDataTable from '../layout/tables/ProductOutOfStockPremDataTable';
import NewProdLaunchPremDataTable from '../layout/tables/NewProdLaunchPremDataTable';
import NoDataModalPremPro from '../modals/NoDataModalPremPro';

// Images
import notepad_icon from '../../img/notepad_icon.png';
import clock_icon from '../../img/clock_icon.png';
import graph_up_icon from '../../img/graph_up_icon.png';
import graph_down_icon from '../../img/graph_down_icon.png';
import stock_icon from '../../img/stock_icon.png';
import newprod_icon from '../../img/newprod_icon.png';
import UpgradeToPro from '../modals/UpgradeToPro';

const AnalyticPremium = ({
  auth: { isAuthenticated, user },
  getPriceMonitoringData,
  getUserIndustryAndPlatform,
  getUserIndustryAndPlatformPrem,
  getPremiumAnalyticData,
  getPremiumAnalyticDataProdOutOfStock,
  getPremiumAnalyticDataNewProdLaunch,
}) => {
  const [selectedProduct, setSelectedProduct] = useState('Price Monitoring');
  const [openFilters, setOpenFilters] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [apiLatestData, setApiLatestData] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshTime, setRefreshTime] = useState(null);
  const [priceHiked, setPriceHiked] = useState('');
  const [priceDropped, setPriceDropped] = useState('');

  const [industryFilterValue, setIndustryFilterValue] = useState();
  const [industryFilterList, setIndustryFilterList] = useState([]);
  let [platformFilterValue, setPlatformFilterValue] = useState([]);
  const [platformFilterList, setPlatformFilterList] = useState([]);
  let [formFactorFilterValue, setformFactorFilterValue] = useState([]);
  const [formFactorFilterList, setformFactorFilterList] = useState([]);
  let [brandFilterValue, setBrandFilterValue] = useState([]);
  const [brandFilterList, setBrandFilterList] = useState([]);
  const [modelFilterValue, setModelFilterValue] = useState([]);
  let [daySelected, setDayselected] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [onFirstLoad, setOnFirstLoad] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null); // State to track the selected row index
  const [showNoDataModalPremPro, setShowNoDataModalPremPro] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [skuCountRecords, setSkuCountRecords] = useState('');
  const [skuCount, setSkuCount] = useState('');

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
    console.log('side bar toggle');
  };

  useEffect(() => {
    document.body.classList.add('with-background');

    return () => {
      document.body.classList.remove('with-background');
    };
  }, []);

  const handleProductClick = (productName) => {
    setSelectedProduct(productName);
    setOpenFilters([]);
  };

  // On Product Change
  useEffect(() => {
    setSidebarOpen(false);
    const fetchData = async () => {
      try {
        // console.log('Product Change', selectedProduct);

        // Only execute the code if selectedProduct is 'Product Out of Stock'
        if (selectedProduct === 'Product Out of Stock') {
          // Call API to load the required data
          onProdOutOfStock();
          // update the Value variable accordingly
          // console.log('Product Change', selectedProduct);
        }

        if (selectedProduct === 'Price Monitoring') {
          // console.log('Product Change', selectedProduct);
          // console.log('Data chnage', selectedProduct);
          onPriceMonitoring();
        }

        if (selectedProduct === 'New Product Launches') {
          // Call API to load the required data
          onNewProdLaunch();
          // console.log('Product Change', selectedProduct);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedProduct]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setCurrentPage(1);
      if (selectedProduct === 'Product Out of Stock') {
        // console.log('Hit product out of stock API onProdOutOfStock');
        onProdOutOfStock();
      }

      if (selectedProduct === 'New Product Launches') {
        // console.log('Hit product API New Product Launches');
        onNewProdLaunch();
      }

      if (selectedProduct === 'Price Monitoring') {
        const response = await getPremiumAnalyticData(
          user.plan_type,
          industryFilterValue,
          formFactorFilterValue,
          brandFilterValue,
          platformFilterValue
        );
        // const data = await response.json();
        setApiData(response.data.records);
        setApiLatestData(response.data.latestData);
        setformFactorFilterList(response.data.distinctFormFactor);
        setBrandFilterList(response.data.distinctBrand);
        setSkuCountRecords(response.data.skuCountRecords);
        // console.log('apiLatestData', apiLatestData);
      }

      // Replace 'API_ENDPOINT' with your actual API endpoint
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // On Initial(2nd) Load
  const onLoad = async () => {
    try {
      // console.log('Premium Analytic');
      setIsLoading(true);
      // Call Action method
      const industryAndPlatform = await getUserIndustryAndPlatformPrem(
        industryFilterValue
      );

      // Set industry filter list with capitalized industries
      const capitalizedIndustries = industryAndPlatform.data.industries.map(
        (industry) =>
          industry.charAt(0).toUpperCase() + industry.slice(1).toLowerCase()
      );
      setIndustryFilterList(capitalizedIndustries);

      // Set default value for industry filter value
      if (capitalizedIndustries.length > 0) {
        setIndustryFilterValue(capitalizedIndustries[0]);
      }

      setOnFirstLoad(true);
      // onPriceMonitoring();

      setPlatformFilterList(industryAndPlatform.data.platformNames);

      // const response = await getPremiumAnalyticData(
      //   user.plan_type,
      //   industryFilterValue,
      //   formFactorFilterValue,
      //   brandFilterValue,
      //   platformFilterValue
      // );
      // // Set Variable values, set API Data, set API Latest Data, Set
      // // console.log('reposne dada', industryAndPlatform.data);
      // setApiData(response.data.records);
      // setApiLatestData(response.data.latestData);

      // setformFactorFilterList(response.data.distinctFormFactor);
      // setBrandFilterList(response.data.distinctBrand);
      // // console.log('test 1', industryAndPlatform.data.filterValue.platform);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // On Industry value change
  useEffect(() => {
    setIsLoading(true);
    setSidebarOpen(false);
    const fetchData = async () => {
      try {
        if (onFirstLoad) {
          setCurrentPage(1);
          // Call Action method
          const industryAndPlatform = await getUserIndustryAndPlatformPrem(
            industryFilterValue
          );

          // Set industry filter list with capitalized industries
          const capitalizedIndustries = industryAndPlatform.data.industries.map(
            (industry) =>
              industry.charAt(0).toUpperCase() + industry.slice(1).toLowerCase()
          );
          setIndustryFilterList(capitalizedIndustries);

          setPlatformFilterList(industryAndPlatform.data.platformNames);

          // if (industryAndPlatform.data.platformNames.length === 1) {
          //   setPlatformFilterValue(industryAndPlatform.data.platformNames[0]);
          // } else {
          //   setPlatformFilterValue([]);
          // }

          if (selectedProduct === 'Price Monitoring') {
            // console.log('Hit Price Monitoring API');
            // onPriceMonitoring();
            onPriceMonitoringIndustryChange();
          }

          if (selectedProduct === 'Product Out of Stock') {
            // console.log('Hit product out of stock API onProdOutOfStock');
            // onProdOutOfStock();
            onProdOutOfStockIndustryChange();
          }

          if (selectedProduct === 'New Product Launches') {
            // console.log('Hit New Product Launches API');
            // onNewProdLaunch();
            onNewProdLaunchIndustryChange();
          }

          if (selectedProduct === 'Price Monitoring') {
            const response = await getPremiumAnalyticData(
              user.plan_type,
              industryFilterValue,
              formFactorFilterValue,
              brandFilterValue,
              platformFilterValue
            );
            setApiData(response.data.records);
            setApiLatestData(response.data.latestData);
            setformFactorFilterList(response.data.distinctFormFactor);
            setBrandFilterList(response.data.distinctBrand);
            setSkuCountRecords(response.data.skuCountRecords);
            setIsLoading(false);
            setPlatformFilterValue([]);
            setformFactorFilterValue([]);
            setBrandFilterValue([]);
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Call the fetchData function initially

    // Add industryFilterValue as a dependency to trigger fetchData when it changes
  }, [industryFilterValue]);

  // On First time Loading
  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log('Product Change', selectedProduct);

        // Only execute the code if selectedProduct is 'Product Out of Stock'
        if (onFirstLoad) {
          // console.log('Price Monitoring First time loading');
          onPriceMonitoring();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [onFirstLoad]);

  // Ftech price monitoring data
  const onPriceMonitoring = async () => {
    setIsLoading(true);
    try {
      // console.log('Price Monitoring Data');
      setCurrentPage(1);
      const response = await getPremiumAnalyticData(
        user.plan_type,
        industryFilterValue,
        formFactorFilterValue,
        brandFilterValue,
        platformFilterValue
      );
      // Set Variable values, set API Data, set API Latest Data, Set
      // console.log('reposne dada', industryAndPlatform.data);
      setApiData(response.data.records);
      setApiLatestData(response.data.latestData);

      setformFactorFilterList(response.data.distinctFormFactor);
      setBrandFilterList(response.data.distinctBrand);
      setSkuCountRecords(response.data.skuCountRecords);
      // console.log('test 1', industryAndPlatform.data.filterValue.platform);

      if (!onFirstLoad) setOnFirstLoad(true);
      setIsLoading(false);

      if (response.data.records.length === 0) {
        setShowNoDataModalPremPro(true);
      } else {
        setShowNoDataModalPremPro(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Ftech price monitoring data when Industry change
  const onPriceMonitoringIndustryChange = async () => {
    setIsLoading(true);
    try {
      // console.log('Price Monitoring Data');
      setCurrentPage(1);
      const response = await getPremiumAnalyticData(
        user.plan_type,
        industryFilterValue,
        (formFactorFilterValue = []),
        (brandFilterValue = []),
        (platformFilterValue = [])
      );
      // Set Variable values, set API Data, set API Latest Data, Set
      // console.log('reposne dada', industryAndPlatform.data);
      setApiData(response.data.records);
      setApiLatestData(response.data.latestData);

      setformFactorFilterList(response.data.distinctFormFactor);
      setBrandFilterList(response.data.distinctBrand);
      setSkuCountRecords(response.data.skuCountRecords);
      // console.log('test 1', industryAndPlatform.data.filterValue.platform);

      if (!onFirstLoad) setOnFirstLoad(true);
      setIsLoading(false);

      if (response.data.records.length === 0) {
        setShowNoDataModalPremPro(true);
      } else {
        setShowNoDataModalPremPro(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch prod out of stock data
  const onProdOutOfStock = async () => {
    try {
      // console.log('Product Out of stock data');
      setIsLoading(true);
      setCurrentPage(1);
      const response = await getPremiumAnalyticDataProdOutOfStock(
        user.plan_type,
        industryFilterValue,
        formFactorFilterValue,
        brandFilterValue,
        platformFilterValue,
        daySelected
      );
      // Set Variable values, set API Data, set API Latest Data, Set
      // console.log('reposne dada', industryAndPlatform.data);
      setApiData(response.data.records);
      setApiLatestData(response.data.latestData);

      setformFactorFilterList(response.data.distinctFormFactor);
      setBrandFilterList(response.data.distinctBrand);
      setSkuCountRecords(response.data.skuCountRecords);
      // console.log('test 1', industryAndPlatform.data.filterValue.platform);
      setIsLoading(false);

      if (response.data.records.length === 0) {
        setShowNoDataModalPremPro(true);
      } else {
        setShowNoDataModalPremPro(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch prod out of stock data on Industry change
  const onProdOutOfStockIndustryChange = async () => {
    try {
      // console.log('Product Out of stock data');
      setIsLoading(true);
      setCurrentPage(1);
      const response = await getPremiumAnalyticDataProdOutOfStock(
        user.plan_type,
        industryFilterValue,
        (formFactorFilterValue = []),
        (brandFilterValue = []),
        (platformFilterValue = []),
        daySelected
      );
      // Set Variable values, set API Data, set API Latest Data, Set
      // console.log('reposne dada', industryAndPlatform.data);
      setApiData(response.data.records);
      setApiLatestData(response.data.latestData);

      setformFactorFilterList(response.data.distinctFormFactor);
      setBrandFilterList(response.data.distinctBrand);
      setSkuCountRecords(response.data.skuCountRecords);
      // console.log('test 1', industryAndPlatform.data.filterValue.platform);
      setIsLoading(false);

      if (response.data.records.length === 0) {
        setShowNoDataModalPremPro(true);
      } else {
        setShowNoDataModalPremPro(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch new product Launch
  const onNewProdLaunch = async () => {
    try {
      // console.log('Product New Arrival');
      setIsLoading(true);
      setCurrentPage(1);
      const response = await getPremiumAnalyticDataNewProdLaunch(
        user.plan_type,
        industryFilterValue,
        formFactorFilterValue,
        brandFilterValue,
        platformFilterValue,
        daySelected
      );
      // Set Variable values, set API Data, set API Latest Data, Set
      // console.log('reposne dada', industryAndPlatform.data);
      setApiData(response.data.records);
      setApiLatestData(response.data.latestData);

      setformFactorFilterList(response.data.distinctFormFactor);
      setBrandFilterList(response.data.distinctBrand);
      setBrandFilterList(response.data.skuCountRecords);
      // console.log('test 1', industryAndPlatform.data.filterValue.platform);
      setIsLoading(false);

      if (response.data.records.length === 0) {
        setShowNoDataModalPremPro(true);
      } else {
        setShowNoDataModalPremPro(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch new product Launch on Industry change
  const onNewProdLaunchIndustryChange = async () => {
    try {
      // console.log('Product New Arrival');
      setIsLoading(true);
      setCurrentPage(1);
      const response = await getPremiumAnalyticDataNewProdLaunch(
        user.plan_type,
        industryFilterValue,
        (formFactorFilterValue = []),
        (brandFilterValue = []),
        (platformFilterValue = []),
        daySelected
      );
      // Set Variable values, set API Data, set API Latest Data, Set
      // console.log('reposne dada', industryAndPlatform.data);
      setApiData(response.data.records);
      setApiLatestData(response.data.latestData);

      setformFactorFilterList(response.data.distinctFormFactor);
      setBrandFilterList(response.data.distinctBrand);
      setBrandFilterList(response.data.skuCountRecords);
      // console.log('test 1', industryAndPlatform.data.filterValue.platform);
      setIsLoading(false);

      if (response.data.records.length === 0) {
        setShowNoDataModalPremPro(true);
      } else {
        setShowNoDataModalPremPro(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // When Day value changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log('Day selected', daySelected);

        // Only execute the code if selectedProduct is 'Product Out of Stock'
        if (selectedProduct === 'Product Out of Stock') {
          // Call API to load the required data
          onProdOutOfStock();
          // update the Value variable accordingly
          // console.log('Product Change', selectedProduct);
        }

        if (selectedProduct === 'Price Monitoring') {
          // console.log('Product Change', selectedProduct);
        }

        if (selectedProduct === 'New Product Launches') {
          onNewProdLaunch();
          // console.log('Product Change', selectedProduct);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [daySelected]);

  // On reset Filter Change
  const onResetFilter = async () => {
    try {
      setIsLoading(true);
      setCurrentPage(1);
      // Call Action method
      const industryAndPlatform = await getUserIndustryAndPlatformPrem();
      // Set industry filter list with capitalized industries
      const capitalizedIndustries = industryAndPlatform.data.industries.map(
        (industry) =>
          industry.charAt(0).toUpperCase() + industry.slice(1).toLowerCase()
      );
      setIndustryFilterList(capitalizedIndustries);

      // Set default value for industry filter value
      if (capitalizedIndustries.length > 0) {
        setIndustryFilterValue(capitalizedIndustries[0]);
      }

      setPlatformFilterList(industryAndPlatform.data.platformNames);
      setformFactorFilterValue([]);
      setBrandFilterValue([]);

      // const response = await getPremiumAnalyticData(
      //   user.plan_type,
      //   industryFilterValue,
      //   (formFactorFilterValue = []),
      //   (brandFilterValue = []),
      //   platformFilterValue
      // );
      // Set Variable values, set API Data, set API Latest Data, Set
      // console.log('reposne dada', response);
      // setApiData(response.data.records);
      // setApiLatestData(response.data.latestData);
      // setData(response.data.data);
      // setIndustryFilterList(industryAndPlatform.data.filterValue.industry);
      // setPlatformFilterList(industryAndPlatform.data.platformNames);
      // setformFactorFilterList(response.data.distinctFormFactor);
      // setBrandFilterList(response.data.distinctBrand);
      // console.log('test 1', industryAndPlatform.data.filterValue.platform);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (user) {
      // console.log('user.plan_type', user.plan_type);
    }
    onLoad(); // Chnage with one time function to get info like, user, industry, platform and related Form factor and Brand name
  }, [user]);

  // Filter apply method
  const onFilterApply = async () => {
    // console.log('onFIlter Apply called');
    setCurrentPage(1);
    try {
      fetchData();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // reset Filter method
  const onFilterReset = async () => {
    // console.log('onFIlter reset called');
    setCurrentPage(1);
    try {
      // Replace 'API_ENDPOINT' with your actual API endpoint
      // setformFactorFilterValue([]);
      // console.log('formFactorFilterValue', formFactorFilterValue);
      // onLoad();
      onResetFilter();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Pagination method
  const getPaginatedItems = (items) => {
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    return items.slice(startIndex, endIndex);
  };

  // day change method
  const onDayChange = (day) => {
    if (user.plan_type === 'pro') {
      setDayselected(day);
    } else {
      if (day !== 0) {
        setShowModal(true);
      }
    }
  };

  const handleNextPageClick = () => {
    setSelectedRowIndex(null);
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setSelectedRowIndex(null);
    setCurrentPage(currentPage - 1);
  };

  const totalAmazonItems = apiData ? apiData.length : 0;
  const totalPages = Math.ceil(totalAmazonItems / 10);

  // console.log('selected Prod', selectedProduct);
  // console.log('selected Prod', industryFilterValue);
  // console.log('all prod', apiData[0].Date);

  // Assuming this useEffect hook is inside your component
  useEffect(() => {
    if (apiData.length > 0) {
      const latestObject = apiData.reduce((latest, current) => {
        const latestTime = new Date(`1970-01-01T${latest.Time}Z`);
        const currentTime = new Date(`1970-01-01T${current.Time}Z`);
        return currentTime > latestTime ? current : latest;
      }, apiData[0]);

      // Count Price Hiked and Price Dropped
      const priceHikedCount = apiData.filter(
        (item) => item.Alert === 'Price Hiked'
      ).length;
      const priceDroppedCount = apiData.filter(
        (item) => item.Alert === 'Price Dropped'
      ).length;

      // Update state only once inside useEffect
      setRefreshTime(latestObject.Time);
      setPriceHiked(priceHikedCount);
      setPriceDropped(priceDroppedCount);

      // Log the counts or use them as needed
      // console.log('Price Hiked Count:', priceHikedCount);
      // console.log('Price Dropped Count:', priceDroppedCount);
    }
  }, [apiData]);

  useEffect(() => {
    if (skuCountRecords.length > 0) {
      const totalSkuCount = skuCountRecords.reduce(
        (sum, record) => sum + record.sku_count,
        0
      );
      // console.log('Total SKU Count:', totalSkuCount);
      setSkuCount(totalSkuCount);
      // You can store the totalSkuCount in state or use it as needed
    }
  }, [skuCountRecords]);

  const latestObject = apiData.reduce((latest, current) => {
    // Compare the time strings
    const latestTime = new Date(`1970-01-01T${latest.Time}Z`);
    const currentTime = new Date(`1970-01-01T${current.Time}Z`);

    // Update if the current object has a later time
    return currentTime > latestTime ? current : latest;
  }, apiData[0]);

  // useEffect(() => {
  //   console.log('SKU COUNT ', skuCount);
  // }, [skuCount]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='flex'>
          {industryFilterList &&
          platformFilterList &&
          brandFilterList &&
          formFactorFilterList ? (
            <div className='sidebar-hidden'>
              <SidebarPremium
                setSidebarOpen={setSidebarOpen}
                industryFilterList={industryFilterList}
                platformFilterList={platformFilterList}
                formFactorFilterList={formFactorFilterList}
                brandFilterList={brandFilterList}
                industryFilterValue={industryFilterValue}
                setIndustryFilterValue={setIndustryFilterValue}
                platformFilterValue={platformFilterValue}
                setPlatformFilterValue={setPlatformFilterValue}
                brandFilterValue={brandFilterValue}
                setBrandFilterValue={setBrandFilterValue}
                formFactorFilterValue={formFactorFilterValue}
                setformFactorFilterValue={setformFactorFilterValue}
                onFilterApply={onFilterApply}
                onFilterReset={onFilterReset}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
              />
            </div>
          ) : (
            ''
          )}

          {industryFilterList &&
          platformFilterList &&
          brandFilterList &&
          formFactorFilterList &&
          sidebarOpen ? (
            <SidebarPremium
              setSidebarOpen={setSidebarOpen}
              industryFilterList={industryFilterList}
              platformFilterList={platformFilterList}
              formFactorFilterList={formFactorFilterList}
              brandFilterList={brandFilterList}
              industryFilterValue={industryFilterValue}
              setIndustryFilterValue={setIndustryFilterValue}
              platformFilterValue={platformFilterValue}
              setPlatformFilterValue={setPlatformFilterValue}
              brandFilterValue={brandFilterValue}
              setBrandFilterValue={setBrandFilterValue}
              formFactorFilterValue={formFactorFilterValue}
              setformFactorFilterValue={setformFactorFilterValue}
              onFilterApply={onFilterApply}
              onFilterReset={onFilterReset}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
            />
          ) : null}

          <div className='flex flex-col w-full analytics-page-1 ml-64 h-full'>
            <Navbar handleSidebarToggle={handleSidebarToggle} />
            <div className='report-card-component-wrapper flex flex-wrap mb-8'>
              {apiData.length > 0 && (
                <ReportCards
                  icon={notepad_icon}
                  card_type={'Report date'}
                  card_data={apiData[0].Date}
                  bgColor={'#BB9BFC'}
                />
              )}
              {apiData.length > 0 && (
                <ReportCards
                  icon={clock_icon}
                  card_type={'Refresh Time'}
                  card_data={refreshTime + ' UTC'}
                  bgColor={'#F97D3A'}
                />
              )}
              {selectedProduct === 'Price Monitoring' && (
                <>
                  {apiData.length > 0 && (
                    <ReportCards
                      icon={graph_up_icon}
                      card_type={'Number of Price Hike'}
                      card_data={priceHiked}
                      bgColor={'#00D6AF'}
                    />
                  )}
                  {apiData.length > 0 && (
                    <ReportCards
                      icon={graph_down_icon}
                      card_type={'Number of Price Dropped'}
                      card_data={priceDropped}
                      bgColor={'#D6B100'}
                    />
                  )}

                  {skuCount > 0 && apiData.length > 0 && (
                    <ReportCards
                      icon={graph_down_icon}
                      card_type={'SKU Count'}
                      card_data={skuCount}
                      bgColor={'#4793AF'}
                    />
                  )}
                </>
              )}
              {selectedProduct === 'Product Out of Stock' &&
                apiData.length > 0 && (
                  <>
                    <ReportCards
                      icon={stock_icon}
                      card_type={'Product Out of Stock'}
                      card_data={apiData.length}
                      bgColor={'#54C24E'}
                    />

                    {skuCount > 0 && apiData.length > 0 && (
                      <ReportCards
                        icon={graph_down_icon}
                        card_type={'SKU Count'}
                        card_data={skuCount}
                        bgColor={'#4793AF'}
                      />
                    )}
                  </>
                )}
              {selectedProduct === 'New Product Launches' &&
                apiData.length > 0 && (
                  <>
                    <ReportCards
                      icon={newprod_icon}
                      card_type={'New Products'}
                      card_data={apiData.length}
                      bgColor={'#0CBFF7'}
                    />

                    {skuCount > 0 && apiData.length > 0 && (
                      <ReportCards
                        icon={graph_down_icon}
                        card_type={'SKU Count'}
                        card_data={skuCount}
                        bgColor={'#4793AF'}
                      />
                    )}
                  </>
                )}
            </div>

            <div>
              {apiLatestData === 0 && (
                <div className='note flex justify-center mx-2'>
                  <span>
                    Today's price change data will be updated shortly.
                  </span>
                </div>
              )}
              {showNoDataModalPremPro && (
                <div className='note flex justify-center mx-2'>
                  <span>Data not available for current selection.</span>
                </div>
              )}
              {/* Other content */}
            </div>

            {(selectedProduct === 'Product Out of Stock' ||
              selectedProduct === 'New Product Launches') && (
              <div className='days-button-prod-out-of-stock flex justify-end'>
                {/* Right-aligned buttons */}
                <button
                  className={`prem-day-30-out-of-stock focus:outline-none ${
                    daySelected === 30
                      ? 'days-button-active'
                      : 'days-button-inactive'
                  }`}
                  onClick={() => onDayChange(30)}
                >
                  30 days
                </button>
                <button
                  className={`prem-day-30-out-of-stock focus:outline-none ${
                    daySelected === 15
                      ? 'days-button-active'
                      : 'days-button-inactive'
                  }`}
                  onClick={() => onDayChange(15)}
                >
                  15 days
                </button>
                <button
                  className={`prem-day-30-out-of-stock focus:outline-none ${
                    daySelected === 7
                      ? 'days-button-active'
                      : 'days-button-inactive'
                  }`}
                  onClick={() => onDayChange(7)}
                >
                  7 days
                </button>
                <button
                  className={`prem-day-30-out-of-stock mr-2 focus:outline-none ${
                    daySelected === 0
                      ? 'days-button-active'
                      : 'days-button-inactive'
                  }`}
                  onClick={() => onDayChange(0)}
                >
                  Current
                </button>
              </div>
            )}

            {/* Modal component */}
            {showModal && <UpgradeToPro onClose={() => setShowModal(false)} />}

            {selectedProduct === 'Product Out of Stock' ? (
              <div className='table-container'>
                <ProductOutOfStockPremDataTable
                  data={apiData}
                  getPaginatedItems={getPaginatedItems}
                  setData={setApiData}
                  daySelected={daySelected}
                  industry={industryFilterValue}
                />
              </div>
            ) : selectedProduct === 'Price Monitoring' ? (
              <div className='table-container'>
                <PriceMonitoringDataTable
                  data={apiData}
                  getPaginatedItems={getPaginatedItems}
                  setData={setApiData}
                  industry={industryFilterValue}
                  selectedRowIndex={selectedRowIndex}
                  setSelectedRowIndex={setSelectedRowIndex}
                />
              </div>
            ) : (
              <div className='table-container'>
                <NewProdLaunchPremDataTable
                  data={apiData}
                  getPaginatedItems={getPaginatedItems}
                  setData={setApiData}
                  industry={industryFilterValue}
                  daySelected={daySelected}
                />
              </div>
            )}

            <div className='w-full flex justify-center pt-1 pb-5 mt-3'>
              <div className='bg-white text-black items-center flex justify-between rounded-full py-1 shadow-md'>
                <button
                  className='prev-button py-1 px-2 focus:outline-none'
                  disabled={currentPage === 1}
                  onClick={handlePrevPage}
                >
                  <i class='fa-solid fa-chevron-left pr-1 pl-1'></i>PREV
                </button>
                <p className='page-count'>
                  {currentPage} | {totalPages}
                </p>
                <button
                  className=' next-button py-1 px-2 focus:outline-none'
                  onClick={handleNextPageClick}
                  disabled={currentPage === totalPages}
                >
                  NEXT<i class='fa-solid fa-chevron-right pl-1'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

AnalyticPremium.propTypes = {
  getPriceMonitoringData: PropTypes.func.isRequired,
  getUserIndustryAndPlatform: PropTypes.func.isRequired,
  getUserIndustryAndPlatformPrem: PropTypes.func.isRequired,
  getPremiumAnalyticData: PropTypes.func.isRequired,
  getPremiumAnalyticDataProdOutOfStock: PropTypes.func.isRequired,
  getPremiumAnalyticDataNewProdLaunch: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getPriceMonitoringData,
  getUserIndustryAndPlatform,
  getUserIndustryAndPlatformPrem,
  getPremiumAnalyticData,
  getPremiumAnalyticDataProdOutOfStock,
  getPremiumAnalyticDataNewProdLaunch,
})(AnalyticPremium);
