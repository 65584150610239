import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SingleSelectionDropdown = ({
  searchInputValues,
  selected,
  setSelected,
}) => {
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className='relative max-w-full bg-white rounded-md custom-css'>
            <Listbox.Button className='relative cursor-default py-1.5 pr-8 pl-2 text-left text-gray-900  focus:outline-none sm:text-sm sm:leading-6 truncate max-w-full'>
              <span className='flex items-center'>
                <span className='ml-2 block truncate'>
                  {selected.industry_name ||
                    selected.category_name ||
                    'Select Category'}
                </span>
              </span>
              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                <i
                  className='fa-solid fa-chevron-down text-gray-500'
                  style={{ fontSize: '15px' }}
                ></i>
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options className='absolute z-10 mt-3 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm min-w-min'>
                {searchInputValues.map((person) => (
                  <Listbox.Option
                    key={person.id}
                    className={({ active }) =>
                      classNames(
                        active
                          ? 'bg-hover_4-10 text-gray-700'
                          : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                        person.disable ? 'opacity-50 pointer-events-none' : ''
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className='flex items-center'>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'ml-3 block truncate'
                            )}
                          >
                            {person.industry_name || person.category_name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-indigo-600' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <i className='fas fa-check h-5 w-5 pl-4'></i>
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default SingleSelectionDropdown;
