import React, { useEffect, useState } from 'react';
import axios from 'axios';

import '../../../css/profile/userProfile.css';

const ProfilePic = ({ defaultProfilePic, className }) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    // Fetch the user's profile picture based on the userId
    const fetchProfilePicture = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/price-now/api/profile-pic`
        );
        // console.log('response.data.imageUrl', response.data.blobUrlWithSas);
        setImageUrl(response.data.blobUrlWithSas);
      } catch (error) {
        console.error('Error fetching profile picture:', error);
      }
    };

    fetchProfilePicture();
  }, []);

  // Conditionally apply the className if it is passed
  const imgClassName = className
    ? `${className}`
    : 'w-8 h-8 rounded-full mr-2 ring-2 ring-gray-300 dark:ring-gray-500 navbar-profile-pic';

  return (
    <img
      src={imageUrl ? imageUrl : defaultProfilePic}
      alt='Profile'
      className={imgClassName}
    />
  );
};

export default ProfilePic;
