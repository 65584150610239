// NoDataModal.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Image
import refresh_icon from '../../img/refresh_icon.png';

// CSS
import '../../css/modals/UpgradeYourPlan.css';

const NoDataModal = () => {
  return (
    <div
      className='relative z-50'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'></div>

      <div className='fixed inset-0 z-50 w-screen overflow-y-auto'>
        <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
          <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-96'>
            {/* Image icon centered aligned */}
            <img
              src={refresh_icon}
              alt='Upgrade Icon'
              className='mx-auto mt-6 upgrade-icon'
            />

            <div className='bg-white px-4 '>
              <div className='flex justify-center'>
                <div className='mt-4 text-center'>
                  {/* Heading text centered aligned */}
                  <h3 className='upgrade-plan-sub-heading' id='modal-title'>
                    Data are not updated!
                  </h3>
                  {/* Subheading text centered aligned */}
                  <div className='mt-2'>
                    <p className='upgrade-plan-sub-text'>
                      Currently, the data has not been refreshed.
                      <br />
                      You can expect to receive the updated data shortly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='bg-gray-50 mt-2 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 justify-center'>
              {/* Center aligned buttons */}
              <Link to='/homepage'>
                <button
                  type='button'
                  className='upgrade-plan-upgrade-button inline-flex w-full justify-center rounded-md px-5 py-2 sm:w-auto focus:outline-none focus:ring-offset-2 focus:ring-button_ring-10 focus:ring-2 tracking-wider'
                >
                  Ok
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoDataModal;
