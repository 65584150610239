import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../navbar/Navbar';
import '../../css/userDetails/myprofile.css';
import { toast } from 'react-toastify';
import axios from 'axios';
import { connect } from 'react-redux';
import Loader from '../loader/Loader';

// Action imports
import { updateProfile, getUser } from '../../actions/auth';

import ProfilePic from '../layout/profile/ProfilePic';
import defaultProfilePic from '../../img/defaultProfilePic.png';

import '../../css/userDetails/editprofile.css';

const MyProfile = ({
  auth: { isAuthenticated, user },
  updateProfile,
  setAlert,
  getUser,
}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile_number: '',
    company_name: '',
    password: '',
    confirm_password: '',
  });

  const [profileData, setProfiledata] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileSizeExceedsLimit, setFileSizeExceedsLimit] = useState(false);
  const [notification, setNotification] = useState('');

  // Destructure Form Data
  const {
    name,
    email,
    mobile_number,
    password,
    confirm_password,
    company_name,
  } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    // Call user API to get his data
    getUser().then((res) => {
      // console.log('user Data', res);
      // setUserdata(res.data);
      setFormData({
        ...formData,
        name: res.data.user_name,
        email: res.data.user_email,
        mobile_number: res.data.user_mobile_number,
        company_name: res.data.user_company,
      });
      setProfiledata(true);
    });
  }, []);

  // Save Changes Button Method
  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirm_password) {
      // setAlert('Password does not match', 'danger');
      toast.error('Confirm password does not matches!');
      // console.log('Password does not matches');
    } else {
      toast.info('Saving Changes...');
      updateProfile({
        name,
        email,
        password,
        mobile_number,
        company_name,
      })
        .then((res) => {
          // setLoading(false);
          // console.log('API return', res);
          if (res.status === 200) {
            toast.success('Profile Updated Successfully');
          }
        })
        .catch(() => {});
    }
  };

  const handleImageInputChange = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      if (imageFile.size > 50 * 1024) {
        setFileSizeExceedsLimit(true);
        toast.warn('Image size should be 50KB or less.');
      } else {
        const allowedFormats = [
          'image/jpeg',
          'image/png',
          'image/gif',
          'image/tiff',
          'image/bmp',
          'image/svg+xml',
          'image/webp',
          'image/heif',
        ];
        if (allowedFormats.includes(imageFile.type)) {
          setFileSizeExceedsLimit(false);
          setSelectedImage(imageFile);
        } else {
          setFileSizeExceedsLimit(true);
          toast.warn('Invalid file format. Please select a valid image file.');
        }
      }
    }
  };

  const handleSave = async () => {
    setUploading(true);
    toast.info('Image uploading...');
    try {
      if (!selectedImage) {
        alert('Please select at least one image to upload.');
        return;
      }
      const formData = new FormData();
      formData.append('image', selectedImage);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}/review-now/api/upload-profile-img`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status === 200) {
        setIsModalOpen(false);
        toast.success('Image Uploaded');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        console.error('Error uploading images:', response.data);
        toast.error('Error Uploading Image');
      }
    } catch (error) {
      console.error('Error uploading images:', error);
      toast.error('Error Uploading Image');
    } finally {
      setUploading(false);
      setTimeout(() => {
        setNotification('');
      }, 3000);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    // console.log('isModalOpen', isModalOpen);
  };

  const profilePicClassName =
    'profile-pic-placeholder rounded-full p-1 ring-2 ring-gray-300 dark:ring-gray-500';

  return (
    <div className='my-profile-page flex flex-col min-h-screen'>
      <Navbar />
      <div className='flex flex-grow items-center justify-center'>
        <div className='my-profile-box rounded-xl shadow-xl'>
          <div className='flex justify-start w-full items-center pt-0 mb-6'>
            <Link to='/homepage' className='my-profile-back-home'>
              <i className='fa-solid fa-chevron-left pr-2 text-xs'></i>Back to
              Home
            </Link>
          </div>
          <div className='flex flex-col md:flex-row'>
            <div className='my-profile-image flex justify-center items-center'>
              <ProfilePic
                defaultProfilePic={defaultProfilePic}
                className={profilePicClassName}
              />
            </div>
            <div className='my-profile-details md:ms-20 mt-6 md:mt-0'>
              <div className='my-profile-personal-details-cards'>
                <h2 className='my-profile-personal-details-heading'>
                  Personal Details
                </h2>
                <div className='flex mt-3 profile-details-wrapper'>
                  <div className='my-profile-header'>
                    <h2>Name:</h2>
                    <h2 className='mt-2'>Mobile Number:</h2>
                    <h2 className='mt-2'>Company:</h2>
                    <h2 className='mt-2'>Email:</h2>
                  </div>
                  <div className='my-profile-information ms-10'>
                    <h2>
                      {name
                        .split(' ')
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(' ')}
                    </h2>
                    <h2 className='mt-2'>{mobile_number}</h2>
                    <h2 className='mt-2'>{company_name}</h2>
                    <h2 className='mt-2 email-value'>{email}</h2>
                  </div>
                </div>
              </div>
              <div className='my-profile-personal-details-cards mt-6'>
                <h2 className='my-profile-personal-details-heading'>
                  Plan Details
                </h2>
                {user && user.plan_type ? (
                  <div className='flex mt-3'>
                    <div className='my-profile-header'>
                      <h2>Plan:</h2>
                    </div>
                    <div className='my-profile-information ms-10'>
                      <h2>{user.plan_type}</h2>
                    </div>
                  </div>
                ) : (
                  <div className='flex mt-3'>
                    <div className='my-profile-header'>
                      <h2>Plan:</h2>
                    </div>
                    <div className='my-profile-information ms-10'>
                      <h2>Not Available</h2>
                    </div>
                  </div>
                )}
              </div>
              <div className='flex justify-start items-start mt-3 my-profile-button-wrapper'>
                <Link to='/edit-profile' className='hover:no-underline'>
                  <button className='my-profile-edit-details-button px-4 py-2 rounded-lg w-full sm:w-auto focus:ring-2 focus:ring-offset-2 focus:ring-button_ring-10 tracking-wider focus:outline-none'>
                    Edit Details
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapstateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapstateToProps, { updateProfile, getUser })(MyProfile);
