import React, { useEffect, useRef, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';

import Loader from '../loader/Loader.js';

import { otpVerification } from '../../actions/auth.js';
import { clearUserRecords } from '../../actions/user.js';

// Component Imports
import OtpSessionTimeOut from '../modals/OtpSessionTimeOut.js';

// Images
import Logo from '../../img/logo.png';
import otp_verification_icon from '../../img/otp_verification_icon.png';

// Css
import '../../css/onBoarding/otpverification.css';

const Otp = ({
  isAuthenticated,
  isOtpVerified,
  otpVerification,
  clearUserRecords,
}) => {
  const [otpValue, setOtpValue] = useState('');
  const inputRef = useRef();

  // Inside your Otp component
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const [remainingTime, setRemainingTime] = useState(120); // 2 minutes in seconds
  const [loading, setLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    // Disable browser back button
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', handleBackButtonPress);

    // Disable page refresh
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Retrieve expiration time from localStorage
    const storedExpirationTime = localStorage.getItem('otpExpirationTime');
    if (storedExpirationTime) {
      const endTime = DateTime.fromISO(storedExpirationTime);
      const currentTime = DateTime.local();
      const diffSeconds = endTime.diff(currentTime, 'seconds').seconds;
      if (diffSeconds > 0) {
        setRemainingTime(diffSeconds);
      }
    }

    return () => {
      window.removeEventListener('popstate', handleBackButtonPress);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleBackButtonPress = (e) => {
    // Prevent default behavior to disable back button
    e.preventDefault();
    window.history.pushState(null, null, window.location.pathname);
  };

  const handleBeforeUnload = (e) => {
    // Confirm dialog to prevent page refresh
    e.preventDefault();
    e.returnValue = 'Are you sure you want to leave this page?';
  };

  useEffect(() => {
    const endTime = DateTime.local().plus({ seconds: remainingTime });
    localStorage.setItem('otpExpirationTime', endTime.toISO());

    const timer = setInterval(() => {
      const currentTime = DateTime.local();
      const diffSeconds = endTime.diff(currentTime, 'seconds').seconds;
      if (diffSeconds <= 0) {
        setIsTimerExpired(true);
        clearInterval(timer);
        clearUserRecords().then((res) => {
          // console.log(res.data);
          // toast.warn(res.data.msg);
          toast.warn('Please re-register yourself');
          localStorage.removeItem('token');
          localStorage.removeItem('otpExpirationTime'); // Remove expiration time on timer expiry
        });
      } else {
        setRemainingTime(diffSeconds);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [remainingTime]);

  const onInputChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/\D/g, ''); // Remove non-digits
    setOtpValue(sanitizedValue);

    // Reset the button's disabled state
    setIsButtonClicked(false);

    // if (sanitizedValue.length === 4) {
    //   inputRef.current.blur(); // Blur the input field when 4 digits are entered
    //   console.log('OTP Success');
    // }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Disable the button to prevent multiple clicks
    setIsButtonClicked(true);

    // Combine OTP values and remove white spaces
    // console.log('Entered OTP:', otpValue);
    setLoading(true);
    otpVerification(otpValue)
      .then((e) => {
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
    // console.log('OTP Success');
  };

  // Redirect if OTP VERIFIED and isAuthenticated
  if (isOtpVerified) {
    toast.success('OTP Verified, Please Login');
    // Navigate to Login page
    return <Navigate to='/login' />;
  }

  // console.log('auth', isAuthenticated);

  // if (!isTimerExpired) {
  const minutes = Math.floor(remainingTime / 60);
  const seconds = Math.floor(remainingTime % 60);
  const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;

  return (
    <div className='flex flex-col min-h-screen otp-verification-page'>
      <div className='flex justify-center sm:pt-4 2xl:pt-7'>
        <img src={Logo} alt='Ecom Next Price Now' className='logo-style' />
      </div>
      <div className='flex flex-grow items-center justify-center p-20'>
        <div className='text-center flex flex-col items-center'>
          <img
            src={otp_verification_icon}
            alt='Otp Verification'
            className=' otp-verification-img'
          />
          <h2 className=' otp-verification-heading font-medium '>
            OTP Verification
          </h2>
          <form onSubmit={(e) => onSubmit(e)} method='POST'>
            <p className='flex justify-center otp-verification-note font-medium '>
              We have sent a verification code to your email.
              <br />
              Please enter it to verify your account.
            </p>
            <input type='hidden' name='remember' defaultValue='true' />
            <input
              value={otpValue}
              onChange={onInputChange}
              pattern='[0-9]{4}'
              maxLength='4'
              required
              className='otp-box otp-box-1 w-1/2 h-12 text-center rounded-lg focus:outline-blue-600 mx-1'
            />
            <div className='flex-row justify-center  w-full'>
              <button
                className={`otp-verification-button otp-verification-button-1  px-4 py-2   rounded-lg w-full focus:ring-2 focus:ring-offset-2 focus:ring-button_ring-10 tracking-wider focus:outline-none ${
                  isButtonClicked ? 'disabled-button' : ''
                }`}
                disabled={isButtonClicked}
              >
                Verify Email
              </button>
            </div>
          </form>
          <div className='otp-verification-timer flex justify-center items-center'>
            <div>
              Session Expires :{' '}
              <span
                className={`timer ${
                  formattedTime.includes('00:')
                    ? 'text-red-500 otp-verification-timer-text'
                    : 'text-green-500 font-medium otp-verification-timer-text'
                } px-1`}
              >
                {formattedTime}
              </span>{' '}
            </div>
          </div>
        </div>
      </div>
      {isTimerExpired ? <OtpSessionTimeOut /> : ''}
    </div>
  );
};

Otp.propTypes = {
  setAlert: PropTypes.func.isRequired,
  otpVerification: PropTypes.func.isRequired,
  // isAuthenticated: PropTypes.bool.isRequired,
  // isOtpVerified: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isOtpVerified: state.auth.isOtpVerified,
});

export default connect(mapStateToProps, {
  otpVerification,
  clearUserRecords,
})(Otp);
