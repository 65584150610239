import {
  PRICE_MONITORING_LIST_SUCCESS,
  PRICE_MONITORING_LIST_FAILED,
  PREMIUM_BASIC_DATA_SUCCESS,
  PREMIUM_BASIC_DATA_FAILED,
  SEARCH_VALUE,
  PREMIUM_PROD_OUT_OF_STOCK_DATA_SUCCESS,
  PREMIUM_PROD_OUT_OF_STOCK_DATA_FAILED,
  PREMIUM_PROD_NEW_LAUNCHES_DATA_SUCCESS,
  PREMIUM_PROD_NEW_LAUNCHES_DATA_FAILED,
} from '../actions/types';

const initialState = {
  isProductLoaded: false,
};

export default function products(state = initialState, action) {
  // destructure action
  const { type, payload } = action;

  switch (type) {
    case SEARCH_VALUE:
      return {
        ...state,
        ...payload,
        isSearchValue: true,
      };
    case PRICE_MONITORING_LIST_SUCCESS:
    case PREMIUM_BASIC_DATA_SUCCESS:
    case PREMIUM_PROD_OUT_OF_STOCK_DATA_SUCCESS:
    case PREMIUM_PROD_NEW_LAUNCHES_DATA_SUCCESS:
      return {
        ...state,
        ...payload,
        isPriceMonitoringData: true,
        isPremiumBasicData: true,
        isPremiumOutofStock: true,
      };
    case PRICE_MONITORING_LIST_FAILED:
    case PREMIUM_BASIC_DATA_FAILED:
    case PREMIUM_PROD_OUT_OF_STOCK_DATA_FAILED:
    case PREMIUM_PROD_NEW_LAUNCHES_DATA_FAILED:
      return {
        ...state,
        ...payload,
        isPriceMonitoringData: false,
        isPremiumBasicData: false,
        isPremiumOutofStock: false,
      };

    default:
      return state;
  }
}
