import { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState } from 'react';
import '../src/App.css';
import './css/global.css';

// Redux
import { Provider } from 'react-redux';
import store from './store';

import setAuthToken from './utils/setAuthToken';
import { loadUser } from './actions/auth';
import Loader from './components/loader/Loader';
import Alert from './components/layout/alert/Alert';
import Analytics from './components/analytic/Analytics';
import Navbar from './components/navbar/Navbar';
import LandingPage from './components/landing/LandingPage';
import { Sidebar } from './components/sidebar/Sidebar';
import Register from './components/onBoarding/Register';
import Login from './components/onBoarding/Login';
import InvalidLink from './components/otherComponents/InvalidLink';
import CheckYourEmail from './components/otherComponents/CheckYourEmail';
import Page404 from './components/otherComponents/Page404';
import AccessDenied from './components/otherComponents/AccessDenied';
import ForgotPassword from './components/otherComponents/ForgotPassword';
import CreateNewPassword from './components/otherComponents/CreateNewPassword';
import OtpVerification from './components/onBoarding/Otp';
import MyProfile from './components/userDetails/MyProfile';
import EditProfile from './components/userDetails/EditProfile';
import PaidPlans from './components/planAndPricing/PaidPlans';
import BasicPriceCard from './components/layout/cards/BasicPlanCard';
import PremiumPlanCard from './components/layout/cards/PremiumPlanCard';
import ProPlanCard from './components/layout/cards/ProPlanCard';
import PaymentSuccess from './components/layout/payment/PaymentSuccess';
import PaymentFail from './components/layout/payment/PaymentFail';
import ContactUs from './components/otherComponents/ContactUs';
import AnalyticPlanType from './components/analytic/AnalyticPlanType';
import UpgradeYourPlan from './components/modals/UpgradeYourPlan';
import SellingPriceGraph from './components/chartsAndGraphs/SellingPriceGraph';
import UserSubscriptions from './components/userDetails/UserSubscriptions';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    store.dispatch(loadUser());
    setLoading(false);
  }, []);

  return (
    <Provider store={store}>
      {loading ? (
        <Loader />
      ) : (
        <Router>
          <Fragment>
            {/* <Navbar></Navbar> */}
            <section className='pr-0 pl-0 bg-all'>
              <Alert></Alert>
              <Routes>
                <Route exact path='/' element={<Login />} />
                <Route exact path='/analytics' element={<AnalyticPlanType />} />
                {/* <Route exact path='/' element={<Analytics />} /> */}
                <Route exact path='/homepage' element={<LandingPage />} />
                <Route exact path='/invalid-link' element={<InvalidLink />} />
                <Route
                  exact
                  path='/check-your-email'
                  element={<CheckYourEmail />}
                />

                <Route exact path='/page404' element={<Page404 />} />
                <Route exact path='/access-denied' element={<AccessDenied />} />
                <Route
                  exact
                  path='/email-forgot-password'
                  element={<ForgotPassword />}
                />
                <Route
                  exact
                  path='/create-new-password'
                  element={<CreateNewPassword />}
                />
                <Route
                  exact
                  path='/otp-verification'
                  element={<OtpVerification />}
                />
                <Route exact path='/profile' element={<MyProfile />} />
                <Route exact path='/edit-profile' element={<EditProfile />} />
                <Route exact path='/pd-plans' element={<PaidPlans />} />
                <Route exact path='/plan-card' element={<BasicPriceCard />} />
                <Route
                  exact
                  path='/plan-card-prem'
                  element={<PremiumPlanCard />}
                />
                <Route exact path='/plan-card-pro' element={<ProPlanCard />} />
                <Route exact path='/pay-success' element={<PaymentSuccess />} />
                <Route exact path='/pay-fail' element={<PaymentFail />} />

                {/* <Route exact path='/navbar' element={<Navbar />} /> */}
                {/* <Route exact path='/sidebar' element={<Sidebar />} /> */}
                {/* <Route exact path='/analytics' element={<Sidebar />} /> */}
                <Route exact path='/register' element={<Register />} />
                <Route exact path='/login' element={<Login />} />
                <Route exact path='/contact-us' element={<ContactUs />} />
                <Route exact path='/plan-type' element={<Analytics />} />
                <Route
                  exact
                  path='/user-subscription'
                  element={<UserSubscriptions />}
                />
                {/* <Route exact path='/upgrade' element={<UpgradeYourPlan />} /> */}
                {/* <Route exact path='/spg' element={<SellingPriceGraph />} /> */}
                {/* <Route exact path='/otp-verification' element={<Otp />} />
                <Route exact path='/dashboard' element={<Dashboard />} />
                <Route
                  exact
                  path='/reset-password'
                  element={<ResetPassword />}
                />
                <Route
                  exact
                  path='/email-forget-password'
                  element={<EmailForgetPassword />}
                />
                <Route exact path='/products-list' element={<ProductsList />} />
                <Route exact path='/templates' element={<Templates />} />
                <Route exact path='/reports-power-bi' element={<Reports />} />
                <Route
                  exact
                  path='/sentiment-analysis'
                  element={<SentimentAnalysis />}
                />
                <Route
                  exact
                  path='/doc-summarisation'
                  element={<DocSummarisation />}
                />
                <Route
                  exact
                  path='/converse-ai-products'
                  element={<AiProducts />}
                />
                <Route exact path='/hr-analytics' element={<HrIframe />} />
                <Route
                  exact
                  path='/revenue-analytics'
                  element={<RevenueAnalytics />}
                />
                <Route exact path='/profile' element={<Profile />} />
                <Route
                  exact
                  path='/manage-profile'
                  element={<ManageProfile />}
      /> */}
                <Route exact path='*' element={<Page404 />} />

                {/* <Route exact path='/analysis-type' element={<AnalysisType />} /> */}
                {/* <Route exact path='/prod-list' element={<StaticProdList />} /> */}
                {/* <Route exact path='/embed' element={<Embeddedreports />} /> */}
                {/* <Route exact path='/about-us' element={<About />} /> */}
                {/* <Route exact path='/contact-us' element={<ContactUs />} />  */}
              </Routes>
            </section>
          </Fragment>
        </Router>
      )}
    </Provider>
  );
};

export default App;
