import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  OTP_VERIFIED,
  OTP_FAILED,
  PASSWORD_RESET_LINK_SUCCESS,
  PASSWORD_RESET_LINK_FAILED,
  PASSWORD_RESET,
  VERIFY_RESET_LINK_SUCCESS,
  VERIFY_RESET_LINK_FAILED,
  VISIT_FORGOT_PASSWORD_LINK,
  USER_OTP_VERIFICATION,
} from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  user: null,
  isResetLinkSent: false,
  fullNavBar: false,
  loader: true,
};

export default function auth(state = initialState, action) {
  // destructure action
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
        fullNavBar: true,
      };

    case REGISTER_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: false,
        isOtpVerified: false,
        loading: false,
        fullNavBar: false,
        isRegistered: false,
        isOtpVerification: true,
      };
    case USER_OTP_VERIFICATION:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        fullNavBar: true,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        isOtpVerified: false,
        loading: false,
        fullNavBar: true,
        isRegistered: true,
      };
    case OTP_VERIFIED:
      localStorage.removeItem('token');
      return {
        ...state,
        ...payload,
        isAuthenticated: false,
        isOtpVerified: true,
        loading: false,
        fullNavBar: true,
        isRegistered: true,
        isModal: true,
      };
    case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAILED:
    case LOGOUT:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        user: null,
        loading: false,
        isOtpVerification: false,
      };
    case OTP_FAILED:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        isOtpVerified: false,
        loading: false,
        isRegistered: false,
      };
    case PASSWORD_RESET_LINK_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: false,
        isResetLinkSent: true,
        loading: false,
      };
    case PASSWORD_RESET_LINK_FAILED:
      return {
        ...state,
        ...payload,
        isAuthenticated: false,
        isResetLinkSent: false,
        loading: false,
      };
    case PASSWORD_RESET:
      return {
        ...state,
        ...payload,
        isAuthenticated: false,
        isResetLinkSent: false,
        isPasswordReset: true,
        isResetLinkValid: true,
        loading: false,
      };
    case VERIFY_RESET_LINK_SUCCESS:
      return {
        ...state,
        ...payload,
        isResetLinkValid: true,
        isPasswordReset: false,
        isAuthenticated: false,
        loading: false,
      };
    case VERIFY_RESET_LINK_FAILED:
      return {
        ...state,
        ...payload,
        isResetLinkValid: false,
        isPasswordReset: false,
        isAuthenticated: false,
        loading: false,
      };
    case VISIT_FORGOT_PASSWORD_LINK:
      return {
        ...state,
        ...payload,
        isResetLinkSent: false,
        isAuthenticated: false,
        loading: false,
      };
    default:
      return state;
  }
}
