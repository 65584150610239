import { Link, Navigate, useLocation } from 'react-router-dom';
import { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { resetPassword, verifyResetLink } from '../../actions/auth';
import queryString from 'query-string';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Loader from '../loader/Loader';

// import '../../css/resetPassword.css';
import '../../css/onBoarding/invalidlink.css';
import '../../css/onBoarding/createnewpassword.css';

// Import Images
import Logo from '../../img/logo.png';
import InvalidLinkLogo from '../../img/invalid_Link_Logo.png';
import create_new_password_icon from '../../img/create_new_password_icon.png';

let CreateNewPassword = ({
  isResetLinkValid,
  resetPassword,
  verifyResetLink,
  setAlert,
  isPasswordReset,
}) => {
  const [formData, setFormData] = useState({
    password: '',
    password2: '',
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.body.classList.add('with-background-4');

    return () => {
      document.body.classList.remove('with-background-4');
    };
  }, []);

  // Destructure formData
  const { password, password2 } = formData;

  const location = useLocation();
  // console.log(location);

  const { token, id } = queryString.parse(location.search);

  const verifyLink = async () => {
    await verifyResetLink(token, id);
  };

  useEffect(() => {
    verifyLink();
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      // setAlert('Password does not match', 'danger');
      toast.error('Password does not match!');
      // console.log('Password does not matches');
    } else {
      setIsLoading(true);
      await resetPassword(password, token, id)
        .then((e) => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
      // console.log('Success');
      // return <Navigate to='/login' replace={true} />;
    }
  };

  // console.log('isPasswordReset', isPasswordReset);
  // Redirect if Password reset success
  if (isPasswordReset) {
    toast.success('Password reset successfully');
    return <Navigate to='/login' />;
  }

  if (!isResetLinkValid)
    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <div className='flex flex-col h-screen h-width page'>
            <div className='flex justify-center sm:pt-4 2xl:pt-7'>
              <img src={Logo} alt='Invalid Link' className='logo-style ' />
            </div>
            <div className='flex flex-grow items-center justify-center'>
              <div className='text-center flex flex-col items-center w-80'>
                <img
                  src={InvalidLinkLogo}
                  alt='Invalid Link'
                  className='img-style '
                />
                <p className='invalid-link-heading sm:pt-9 2xl:pt-14 font-medium'>
                  Invalid Link
                </p>

                <p className='flex justify-center invaild-link-note sm:pt-9 2xl:pt-14 font-medium'>
                  We regret to inform you that the reset password
                  <br></br> link you attempted to use is invalid. Please make a
                  <br></br>
                  new request to reset your password.
                </p>
                <div className='flex justify-center sm:pt-9 2xl:pt-14'>
                  <Link
                    to='/email-forgot-password'
                    className='hover:no-underline'
                  >
                    <button
                      type='button'
                      className='invalid-button-link  invalid-button-link-1 flex justify-center items-center px-4 py-2   rounded-lg w-full sm:w-auto focus:ring-2 focus:ring-offset-2 focus:ring-button_ring-10 tracking-wider focus:outline-none no-underline'
                    >
                      Back To forget password
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='flex flex-col min-h-screen page'>
          <div className='flex justify-center sm:pt-4 2xl:pt-7'>
            <img src={Logo} alt='Ecom Next Price Now' className='logo-style' />
          </div>
          <div className='flex flex-grow items-center justify-center'>
            <div className='text-center flex flex-col items-center w-80'>
              <img
                src={create_new_password_icon}
                alt='Page 404'
                className='new-password-img-style'
              />
              <h2 className=' new-password-heading font-medium sm:pt-9 2xl:pt-11'>
                Create New Password
              </h2>
              <form onSubmit={(e) => onSubmit(e)} method='POST'>
                <label className='new-password-note  flex justify-center sub-text font-medium sm:pb-4 2xl:pb-14'>
                  Please enter and confirm your new password.
                </label>
                <input type='hidden' name='remember' defaultValue='true' />
                <input
                  id='password'
                  name='password'
                  type='password'
                  value={password}
                  onChange={(e) => onChange(e)}
                  required
                  placeholder='Password'
                  minLength='6'
                  className='new-password-register-input-field new-password-register-input-field-1  w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative '
                />
                <input type='hidden' name='remember' defaultValue='true' />
                <input
                  id='password2'
                  name='password2'
                  type='password'
                  value={password2}
                  onChange={(e) => onChange(e)}
                  required
                  placeholder='Confirm new Password'
                  minLength='6'
                  className='new-password-register-input-field new-password-register-input-field-1 pt-4 w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative '
                />
                <div className='flex-row justify-center sm:pt-9 2xl:pt-14 w-full'>
                  {/* <Link to='/login' className=' hover:no-underline'> */}
                  <button
                    type='submit'
                    className='new-password-register-button new-password-register-button-1 px-4 py-2   rounded-lg w-full  focus:ring-2 focus:ring-offset-2 focus:ring-button_ring-10 tracking-wider focus:outline-none'
                  >
                    OK
                  </button>
                  {/* </Link> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

CreateNewPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  // isAuthenticated: PropTypes.bool.isRequired,
  // isPasswordReset: PropTypes.bool.isRequired,
  // isResetLinkValid: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isPasswordReset: state.auth.isPasswordReset,
  isResetLinkValid: state.auth.isResetLinkValid,
  isTokenValid: state.auth.isTokenValid,
});

export default connect(mapStateToProps, {
  resetPassword,
  verifyResetLink,
})(CreateNewPassword);
