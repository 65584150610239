import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Css
import '../../../css/tables/userSubscriptionTable.css';

const columns = [
  'Industry',
  'Category',
  'Platform',
  'Start Date',
  'End Date',
  'Active/Inactive',
];

const capitalizeAndFormat = (str) => {
  if (!str) return '';

  // Replace underscores with spaces
  let formattedStr = str.replace(/_/g, ' ');

  // Capitalize the first letter of each word
  return formattedStr
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const UserSubscriptionTable = ({
  auth: { isAuthenticated, user },
  data,
  getPaginatedItems,
  setData,
}) => {
  const [isAscendingOrder, setIsAscendingOrder] = useState(true);

  useEffect(() => {
    if (user) {
      // console.log('user.plan_type', user.plan_type);
    }
  }, [user]);

  const onClickTableHeader = () => {
    // Toggle the sorting order
    setIsAscendingOrder((prev) => !prev);
    // Sort the rows based on the "Alert" value
    const sortedData = [...data].sort((a, b) => {
      if (isAscendingOrder) {
        return a.Alert.localeCompare(b.Alert);
      } else {
        return b.Alert.localeCompare(a.Alert);
      }
    });
    // Update the 'data' prop with the sorted data
    setData(sortedData);
    // console.log('sorted data', sortedData);
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toISOString().split('T')[0]; // Extracts and returns only the date part
  };

  return (
    <div className='table-top-wrapper shadow-lg'>
      <h2
        className='text-center table-header'
        style={{ fontSize: '16px', padding: '12px 0px 12px 0px' }}
      >
        Your Subscriptions
      </h2>
      <table className='min-w-full bg-white border border-gray-300'>
        <thead
          className='table-column-head'
          style={{ fontSize: '12px', fontWeight: 800 }}
        >
          <tr>
            {columns.map((column) => (
              <th
                key={column}
                className='border-b text-center py-2'
                // onClick={
                //   column === 'Active/Inactive' ? onClickTableHeader : undefined
                // }
              >
                {column === 'Active/Inactive' ? (
                  <>
                    <span className='hover:cursor-pointer'>
                      Active/Inactive{' '}
                    </span>
                    <span>
                      <i className='fa-solid fa-sort ml-1 hover:cursor-pointer'></i>
                    </span>
                  </>
                ) : (
                  column
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {getPaginatedItems(data).map((row, index) => (
            <React.Fragment key={index}>
              <tr style={{ fontSize: '12px', textAlign: 'center' }}>
                <td className='p-2'>{capitalizeAndFormat(row['industry'])}</td>
                <td className='p-2'>{capitalizeAndFormat(row['category'])}</td>
                <td className='p-2'>
                  {row['platform_name']}
                  {/* model name */}
                </td>
                {/* <td className='border-b p-2'>{row['MRP']}</td> */}
                <td className='p-2'>{formatDate(row['createdAt'])}</td>
                <td className='p-2'>{formatDate(row['valid_till'])}</td>

                <td
                  className={`p-2 ${
                    new Date(row['valid_till']) > new Date()
                      ? 'active-subscription'
                      : 'inactive-subscription'
                  }`}
                >
                  {new Date(row['valid_till']) > new Date()
                    ? 'Active'
                    : 'Inactive'}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

UserSubscriptionTable.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(UserSubscriptionTable);
