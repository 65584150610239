import React from 'react';
import { Link } from 'react-router-dom';

//Image
import custom_plan_icon from '../../../img/custom_plan_icon.png';

//CSS
import '../../../css/layout/cards/PriceCard.css';

const CustomPlanCard = () => {
  return (
    <div className='max-w-sm overflow-hidden bg-white shadow-lg rounded-lg plan-card-div'>
      {/* Top Section */}
      <div className='flex items-center justify-between p-2 bg-white text-white'>
        {/* Left side (image) */}
        <div className='flex-shrink-0'>
          <img
            className='plan-card-icon'
            src={custom_plan_icon}
            alt='custom Icon'
          />
        </div>

        {/* Right side (text: premium and price) */}
        <div className='text-right'>
          <p className='plan-card-title'>Let's Talk</p>
          {/* <p className='plan-card-price'>
            ₹200 <span className='per-user'>/ user</span>
          </p> */}
        </div>
      </div>

      {/* Horizontal Line */}
      <hr className='mx-4 border-t border-gray-300' />

      {/* Features Section */}
      <div className='p-4'>
        {/* Features Heading */}
        <p className='custom-card-text'>
          For a personalized plan to meet your unique needs, Please reach out to
          us.
        </p>
      </div>

      {/* Link button */}
      <div className='flex justify-center mt-auto '>
        <Link
          to='/contact-us'
          className='plan-card-link-button focus:ring-offset-2 focus:ring-2 focus:ring-button_ring-10'
        >
          Connect with us <i className='fa-solid fa-arrow-right text-xs'></i>
        </Link>
      </div>
    </div>
  );
};

export default CustomPlanCard;
