import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../img/logo.png';
import page404_icon from '../../img/access_denied_icon.png';
import '../../css/onBoarding/accessdenied.css';

const AccessDenied = () => {
  return (
    <div className='flex flex-col min-h-screen page'>
      <div className='flex justify-center sm:pt-4 2xl:pt-7'>
        <img src={Logo} alt='Ecom Next Price Now' className='logo-style-1' />
      </div>
      <div className='flex flex-grow items-center justify-center access-denied-main-wrapper'>
        <div className='text-center flex flex-col items-center'>
          <img src={page404_icon} alt='Page 404' className='img-style-1' />
          <h2 className=' access-denied-heading sm:pt-9 2xl:pt-14 font-medium'>
            Access Denied
          </h2>

          <p className='flex justify-center access-denied-note sm:pt-9 2xl:pt-14 font-medium'>
            We apologize, but the dashboard is currently<br></br> inaccessible
            without a valid account. Please log<br></br> in with your existing
            credentials or create a new<br></br> account to access it.
          </p>
          <div className='flex-row justify-center sm:pt-9 2xl:pt-14 w-full'>
            <Link to='/login' className=' hover:no-underline'>
              <button className='access-denied-login-button access-denied-login-button-1 px-4 py-2   rounded-lg w-full sm:w-auto focus:ring-2 focus:ring-offset-2 focus:ring-button_ring-10 tracking-wider focus:outline-none'>
                Login
              </button>
            </Link>
            <Link to='/register' className=' hover:no-underline'>
              <button className='access-denied-register-button access-denied-register-button-1  px-4 py-2   rounded-lg w-full sm:w-auto focus:ring-2 focus:ring-offset-2 focus:ring-button_ring-10 tracking-wider focus:outline-none'>
                Register
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
