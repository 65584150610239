import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//Image
import basic_plan_card_icon from '../../../img/basic_plan_icon.png';

//CSS
import '../../../css/layout/cards/PriceCard.css';
import { toast } from 'react-toastify';

const BasicPlanCard = ({ auth: { user } }) => {
  const [planType, setPlanType] = useState(null);

  useEffect(() => {
    if (user) {
      setPlanType(user.plan_type);
    }
  }, [user]);

  const navigate = useNavigate(); // <-- Initialize useNavigate hook

  const handleFreeClick = () => {
    // Your method to check the defined condition
    if (planType === 'pro' || planType === 'premium') {
      toast.error(`Already a ${planType} user.`);
      return;
    } else {
      navigate('/analytics');
    }
    // If the condition is met, you can navigate or perform other actions
  };

  return (
    <div className='max-w-sm overflow-hidden bg-white shadow-lg rounded-lg plan-card-div'>
      {/* Top Section */}
      <div className='flex items-center justify-between p-2 bg-white text-white'>
        {/* Left side (image) */}
        <div className='flex-shrink-0'>
          <img
            className='plan-card-icon'
            src={basic_plan_card_icon}
            alt='bsic Icon'
          />
        </div>

        {/* Right side (text: premium and price) */}
        <div className='text-right'>
          <p className='plan-card-title'>Basic</p>
          {/* <p className='plan-card-price'>
            ₹200 <span className='per-user'>/ user</span>
          </p> */}
        </div>
      </div>

      {/* Horizontal Line */}
      <hr className='mx-4 border-t border-gray-300' />

      {/* Features Section */}
      <div className='p-4'>
        {/* Features Heading */}
        <p className='plan-card-feature-text'>FEATURES</p>

        {/* List of Features */}
        <ul className='text-sm'>
          <li className='mb-2'>
            <i class='fa-solid fa-circle-check feature-tick-green mr-2'></i>
            Price Monitoring
          </li>
          <li className='mb-2'>
            <i class='fa-solid fa-circle-xmark feature-cross-red mr-2'></i>
            30 Days Historic Graph
          </li>
          <li className='mb-2'>
            <i class='fa-solid fa-circle-xmark feature-cross-red mr-2'></i>
            Product Out of Stock Report
          </li>
          <li className='mb-2'>
            <i class='fa-solid fa-circle-xmark feature-cross-red mr-2'></i>
            New Product launches Report
          </li>
          <li className='mb-2'>
            <i class='fa-solid fa-circle-xmark feature-cross-red mr-2'></i>
            Reports Download
          </li>
          <li className='mb-2'>
            <i class='fa-solid fa-circle-xmark feature-cross-red mr-2'></i>
            30 Days Historic Data
          </li>
          <li className='mb-2'>
            <i class='fa-solid fa-circle-xmark feature-cross-red mr-2'></i>
            Twice Data Refresh
          </li>

          {/* Add more features as needed */}
        </ul>
      </div>

      {/* Link button */}
      <div className='flex justify-center mt-2'>
        <button
          to='/analytics'
          className='plan-card-link-button focus:ring-offset-2 focus:ring-2 focus:ring-button_ring-10'
          onClick={handleFreeClick}
        >
          Free <i className='fa-solid fa-arrow-right text-xs'></i>
        </button>
      </div>
    </div>
  );
};

BasicPlanCard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(BasicPlanCard);
