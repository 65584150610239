import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import sessionexpired_icon from '../../img/session_expired_icon.png';
import '../../css/otpsessiontimeout/otpsessiontimeout.css';

const OtpSessionTimeOut = () => {
  return (
    <div>
      <div className='fixed inset-0 otp-page flex items-center justify-center'>
        <div className='text-center box-size flex flex-col items-center bg-white p-14 rounded-2xl shadow-2xl'>
          <img
            src={sessionexpired_icon}
            alt='Session Expire icon'
            className='session-erpire-icon'
          />
          <h2 className='session-primary-text font-semibold sm:pt-9 2xl:pt-12 '>
            Session Expired !
          </h2>

          <p className='session-secondary-text flex justify-center  font-medium sm:pt-9 2xl:pt-12 '>
            Your OTP session has expired. To proceed, kindly register again.
            Click OK to return to Register page.
          </p>
          <div className='flex justify-center sm:pt-9 2xl:pt-12'>
            <Link
              to='/register'
              className='flex justify-center items-center session-button-link session-button-link-1   py-2 px-4 border border-transparentfocus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hover_1-10 tracking-wider hover:no-underline'
            >
              Ok
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpSessionTimeOut;
