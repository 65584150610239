import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Component
import UpgradeYourPlan from '../../modals/UpgradeYourPlan';

// CSS
import '../../../css/tables/priceMonitoringDataTable.css';

//Utils
import { downloadFullReport } from '../../utils/csvUtils';

const columns = [
  'Product',
  'Form Factor',
  'SKU ID',
  'Brand',
  'Model Name',
  'Unavailability',
];

const mockApiData = [
  // Example data, replace this with actual API response
  // ...
];

const rowsPerPage = 10;

const ProductOutOfStockPremDataTable = ({
  auth: { user },
  data,
  getPaginatedItems,
  setData,
  daySelected,
  industry,
}) => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(mockApiData);
  const [isAscendingOrder, setIsAscendingOrder] = useState(true);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false); // State to control modal visibility
  const [planType, setPlanType] = useState('free');

  useEffect(() => {
    // Fetch your API data and update the 'rows' state
    // Example: fetchApiData().then(data => setRows(data));
  }, []);

  useEffect(() => {
    if (user) {
      // console.log('user.plan_type', user.plan_type);
      setPlanType(user.plan_type);
    }
  }, [user]);

  // console.log('dd', data);
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedRows = rows.slice(startIndex, endIndex);

  const isNextDisabled = data.length <= rowsPerPage;

  const openUpgradeModal = () => {
    setIsUpgradeModalOpen(true);
    // console.log('isUpgradeModalOpen', isUpgradeModalOpen);
  };

  const closeUpgradeModal = () => {
    setIsUpgradeModalOpen(false);
    // console.log('isUpgradeModalOpen', isUpgradeModalOpen);
  };

  // let isAscendingOrder = true; // Variable to track the sorting order

  const onClickTableHeader = () => {
    // Toggle the sorting order
    setIsAscendingOrder((prev) => !prev);

    // Sort the rows based on the "unavailable" value
    const sortedData = [...data].sort((a, b) => {
      if (isAscendingOrder) {
        return a.unavailable - b.unavailable; // Ascending order
      } else {
        return b.unavailable - a.unavailable; // Descending order
      }
    });

    // Update the 'data' prop with the sorted data
    setData(sortedData);
    // console.log('sorted data', sortedData);
  };

  if (daySelected === 0) {
    console.log('days Selected', daySelected);
  }

  const fields = [
    { key: 'Product_Name', label: 'Product Name' },
    { key: 'Form_Factor', label: 'Form Factor' },
    { key: 'Generic_Name', label: 'SKU ID' },
    { key: 'Brand', label: 'Brand' },
    { key: 'Model_Name', label: 'Model Name' },
    ...(daySelected === 7 || daySelected === 15 || daySelected === 30
      ? [{ key: 'unavailable', label: 'Unavailability' }]
      : []),
  ];

  const constructFileName = () => {
    const currentDate = new Date()
      .toISOString()
      .split('T')[0]
      .replace(/-/g, '');
    return `ProductOutOfStock_Report_${industry}_${currentDate}.csv`;
  };

  return (
    <div className=' mx-2 shadow-lg mobile-view-table-wrapper'>
      <div className='header-container'>
        <div className='header-wrapper'>
          <h2 className='table-header'>Out of Stock Report</h2>
        </div>
        {(planType === 'pro' || planType === 'premium') && (
          <button
            className='download-button'
            onClick={() =>
              downloadFullReport(data, fields, constructFileName())
            }
            disabled={!data || data.length === 0}
          >
            {' '}
            <i className='fa-solid fa-download mr-2'></i>Download Report
          </button>
        )}
      </div>
      <table className='min-w-full bg-white border border-gray-300'>
        <thead className='table-column-head'>
          <tr>
            {columns.map(
              (column) =>
                // Conditionally render the column header based on daySelected value
                (daySelected > 0 || column !== 'Unavailability') && (
                  <th
                    key={column}
                    className='border-b text-left pl-2 py-2 text-center'
                    onClick={
                      column === 'Unavailability'
                        ? onClickTableHeader
                        : undefined
                    }
                  >
                    {column === 'Unavailability' ? (
                      <>
                        <span className='hover:cursor-pointer'>
                          Unavailability{' '}
                        </span>
                        <span>
                          <i className='fa-solid fa-sort ml-1 hover:cursor-pointer'></i>
                        </span>
                      </>
                    ) : (
                      column
                    )}
                  </th>
                )
            )}
            {/* <th className='border-b p-3'></th> */}
          </tr>
        </thead>
        <tbody className='text-center'>
          {getPaginatedItems(data).map((row, index) => (
            <tr key={index} className='table-data-row'>
              <td className='border-b p-2 flex justify-center'>
                <div className='relative w-10 h-10 my-2'>
                  <img
                    src={row['Image_url']}
                    alt='Prod img'
                    className='absolute inset-0 object-contain w-full h-full'
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                  />
                </div>
              </td>
              <td className='border-b p-2'>{row['Form_Factor']}</td>
              <td className='border-b p-2'>{row['Generic_Name']}</td>
              <td className='border-b p-2'>{row['Brand']}</td>
              <td className='border-b p-2'>{row['Model_Name']}</td>
              {/* Conditionally render the "Unavailable Days Count" column */}
              {daySelected > 0 && (
                <td className='border-b p-2'>{row['unavailable']}</td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {/* UpgradeYourPlan modal */}
      {isUpgradeModalOpen && <UpgradeYourPlan onClose={closeUpgradeModal} />}
    </div>
  );
};

// export default PriceMonitoringDataTable;

ProductOutOfStockPremDataTable.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(ProductOutOfStockPremDataTable);

// export default ProductOutOfStockPremDataTable;
