// PaymentSuccessPage.js

import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import Navbar from '../../navbar/Navbar';

// Image Import
import pay_success_icon from '../../../img/pay_success_icon.png';

// CSS
import '../../../css/layout/payment/paymentFailSuccess.css';

const PaymentSuccess = () => {
  const [query] = useSearchParams();

  // console.log('payment_id', query.get('payment_id'));
  const paymentId = query.get('payment_id'); // Get the payment ID from query parameters

  return (
    <div className='flex flex-col h-screen'>
      <Navbar />

      <div className='flex-col flex items-center justify-center shadow-lg pay-success-box mx-auto my-auto'>
        <img src={pay_success_icon} alt='Logo' className='mb-4 pay-icon' />

        <h2 className='payment-success-heading'>Payment Success !</h2>

        {paymentId && ( // Display payment ID if it exists
          <div className='payment-success-id-container flex items-center justify-between w-full'>
            <span className='payment-id-label'>Payment ID: </span>
            <span className='payment-id'>{paymentId}</span>
          </div>
        )}

        {/* <hr className='payment-success-hr' /> */}

        {/* <div className='payment-success-info'>
          <table className='payment-info-table'>
            <tbody>
              <tr>
                <td>Transaction ID:</td>
                <td>2843208</td>
              </tr>
              <tr>
                <td>Amount:</td>
                <td>$5000</td>
              </tr>
            </tbody>
          </table>
        </div> */}

        <Link to='/analytics'>
          <button className='payment-success-start-button px-10 py-2 focus:outline-none focus:ring-offset-2 focus:ring-button_ring-10 focus:ring-2 tracking-wider mt-6'>
            OK
          </button>
        </Link>
      </div>
    </div>
  );
};

export default PaymentSuccess;
