// PaymentFailPage.js

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../navbar/Navbar';

// Images
import pay_fail_icon from '../../../img/pay_fail_icon.png';

// CSS
import '../../../css/layout/payment/paymentFailSuccess.css';

const PaymentFail = () => {
  return (
    <div className='flex flex-col h-screen'>
      <Navbar />

      <div className='flex-col flex items-center justify-center shadow-lg pay-fail-box mx-auto my-auto'>
        <img src={pay_fail_icon} alt='Logo' className='mb-4 pay-icon' />

        <h2 className='payment-fail-success-heading'>Payment Failed !</h2>

        <p className='payment-fail-success-subtext text-center'>
          Payment processing failed. Please initiate the payment process again.
        </p>

        <Link to='/pd-plans'>
          <button className='payment-fail-retry-button px-10 py-2 focus:outline-none focus:ring-offset-2 focus:ring-button_ring-10 focus:ring-2 tracking-wider'>
            Retry Payment
          </button>
        </Link>
      </div>
    </div>
  );
};

export default PaymentFail;
