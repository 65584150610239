import React from 'react';

import '../../../css/layout/cards/ReportsCard.css';

const ReportCards = ({ icon, card_type, card_data, bgColor }) => {
  return (
    <div className='card card-div'>
      <div
        className='flex items-center justify-center h-full card-internal-wrapper'
        style={{ backgroundColor: bgColor }}
      >
        <img src={icon} alt='Icon' className='card-icon mr-2' />
        <div className='text-left'>
          <div className='title card-title mb-0'>{card_type}</div>
          <div className='date card-data mt-0'>{card_data}</div>
        </div>
      </div>
    </div>
  );
};

export default ReportCards;
