import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Component
import UpgradeYourPlan from '../../modals/UpgradeYourPlan';

// CSS
import '../../../css/tables/priceMonitoringDataTable.css';
import SellingPriceGraph from '../../chartsAndGraphs/SellingPriceGraph';

//Utils
import { downloadFullReport } from '../../utils/csvUtils';

const columns = [
  'Product',
  'Form Factor',
  'SKU ID',
  'Brand',
  'Model Name',
  // 'MRP',
  'Selling Price',
  'Prev Day Selling Price',
  // 'Change in Price',
  'Price Diff %',
  'Alert',
];

const mockApiData = [
  // Example data, replace this with actual API response
  // ...
];

const rowsPerPage = 10;

const PriceMonitoringDataTable = ({
  auth: { isAuthenticated, user },
  data,
  getPaginatedItems,
  setData,
  industry,
  selectedRowIndex,
  setSelectedRowIndex,
}) => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(mockApiData);
  const [isAscendingOrder, setIsAscendingOrder] = useState(true);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false); // State to control modal visibility
  // const [selectedRowIndex, setSelectedRowIndex] = useState(null); // State to track the selected row index
  const [isSellingGraphOpen, setIsSellingGraphOpen] = useState(false); // State to control the visibility of the SellingPriceGraph
  const [productName, setProductName] = useState(null); // State to control the visibility of the SellingPriceGraph
  const [planType, setPlanType] = useState('free');

  useEffect(() => {
    // Fetch your API data and update the 'rows' state
    // Example: fetchApiData().then(data => setRows(data));
  }, []);

  useEffect(() => {
    if (user) {
      // console.log('user.plan_type', user.plan_type);
      setPlanType(user.plan_type);
    }
  }, [user]);

  // console.log('dd', data);
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedRows = rows.slice(startIndex, endIndex);

  const openUpgradeModal = () => {
    setIsUpgradeModalOpen(true);
    // console.log('isUpgradeModalOpen', isUpgradeModalOpen);
  };

  const closeUpgradeModal = () => {
    setIsUpgradeModalOpen(false);
    // console.log('isUpgradeModalOpen', isUpgradeModalOpen);
  };

  const handleDetailsClick = (rowIndex) => {
    if (user.plan_type === 'free') {
      setIsUpgradeModalOpen(true);
    } else {
      // Toggle the visibility of the SellingPriceGraph for the selected row
      setSelectedRowIndex(rowIndex === selectedRowIndex ? null : rowIndex);
      // setIsSellingGraphOpen(true); // Open the SellingPriceGraph when "Details" is clicked
      setIsSellingGraphOpen((prev) => !prev);
      setProductName(getPaginatedItems(data)[rowIndex]['Product_Name']);

      // if (selectedRowIndex == null) {
      //   // Call API to get the historic data for selected Products
      //   console.log('selectedRowIndex', selectedRowIndex);
      //   console.log(
      //     'Product Name:',
      //     getPaginatedItems(data)[rowIndex]['Product_Name']
      //   );
      // }
    }
  };

  // Function to toggle the visibility of the SellingPriceGraph
  const toggleSellingGraph = () => {
    setIsSellingGraphOpen((prev) => !prev);
  };

  const getRowClass = (row) => {
    switch (row['Alert']) {
      case 'Price Hiked':
        return 'price-hiked';
      case 'Price Dropped':
        return 'price-dropped';
      case 'No change in price':
        return 'no-price-change';
      case 'New Arrival':
        return 'new-arrival';
      default:
        return '';
    }
  };

  const getIcon = (alert) => {
    switch (alert) {
      case 'Price Hiked':
        return <i className='fa-solid fa-arrow-up mr-1'></i>;
      case 'Price Dropped':
        return <i className='fa-solid fa-arrow-down mr-1'></i>;
      default:
        return null;
    }
  };

  // let isAscendingOrder = true; // Variable to track the sorting order

  const onClickTableHeader = () => {
    if (user.plan_type !== 'free') {
      setSelectedRowIndex(null);
    }

    // Toggle the sorting order
    setIsAscendingOrder((prev) => !prev);

    // Sort the rows based on the "Alert" value
    const sortedData = [...data].sort((a, b) => {
      if (isAscendingOrder) {
        return a.Alert.localeCompare(b.Alert);
      } else {
        return b.Alert.localeCompare(a.Alert);
      }
    });

    // Update the 'data' prop with the sorted data
    setData(sortedData);
    // console.log('sorted data', sortedData);
  };

  // console.log('isUpgradeModalOpen', isUpgradeModalOpen);

  // const downloadFullReport = () => {
  //   const csvData = convertToCSV(data, fields);
  //   const blob = new Blob([csvData], { type: 'text/csv' });
  //   const url = window.URL.createObjectURL(blob);

  //   const currentDate = new Date()
  //     .toISOString()
  //     .split('T')[0]
  //     .replace(/-/g, '');
  //   const fileName = `PriceMonitoring_Alert_Report_${industry}_${currentDate}.csv`;

  //   const a = document.createElement('a');
  //   a.setAttribute('href', url);
  //   a.setAttribute('download', fileName);
  //   a.click();
  // };

  // Fields to include in the CSV
  const fields = [
    { key: 'Product_Name', label: 'Product Name' },
    { key: 'Form_Factor', label: 'Form Factor' },
    { key: 'Generic_Name', label: 'SKU ID' },
    { key: 'Brand', label: 'Brand' },
    { key: 'Model_Name', label: 'Model Name' },
    { key: 'Discounted_Price', label: 'Selling Price' },
    { key: 'prev_Discounted_Price', label: 'Prev Day Selling Price' },
    // { key: 'Change_in_Price', label: 'Change in Price' },
    { key: 'Discount_diff_percentage', label: 'Discounted Diff %' },
    { key: 'Alert', label: 'Alert' },
  ];

  const constructFileName = () => {
    const currentDate = new Date()
      .toISOString()
      .split('T')[0]
      .replace(/-/g, '');
    return `PriceMonitoring_Alert_Report_${industry}_${currentDate}.csv`;
  };

  // Function to convert data to CSV
  // const convertToCSV = (data) => {
  //   const csvRows = [];

  //   // Add the header row
  //   const headers = fields.map((field) => field.label).join(',');
  //   csvRows.push(headers);

  //   // Add the data rows
  //   for (const item of data) {
  //     const row = fields.map((field) => `"${item[field.key]}"`).join(',');
  //     csvRows.push(row);
  //   }

  //   return csvRows.join('\n');
  // };

  return (
    <div className=' mx-2 shadow-lg mobile-view-table-wrapper'>
      <div className='header-container'>
        <div className='header-wrapper'>
          <h2 className='table-header text-center'>Alert Report</h2>
        </div>
        {(planType === 'pro' || planType === 'premium') && (
          <button
            className='download-button'
            onClick={() =>
              downloadFullReport(data, fields, constructFileName())
            }
            disabled={!data || data.length === 0}
          >
            {' '}
            <i className='fa-solid fa-download mr-2'></i>Download Report
          </button>
        )}
      </div>
      <table className='min-w-full bg-white border border-gray-300'>
        <thead className='table-column-head'>
          <tr>
            {columns.map((column) => (
              <th
                key={column}
                className='border-b text-left pl-2'
                onClick={column === 'Alert' ? onClickTableHeader : undefined}
              >
                {column === 'Alert' ? (
                  <>
                    <span className='hover:cursor-pointer'>Alerts </span>
                    <span>
                      <i className='fa-solid fa-sort ml-1 hover:cursor-pointer'></i>
                    </span>
                  </>
                ) : (
                  column
                )}
              </th>
            ))}
            <th className='border-b p-3'></th>
          </tr>
        </thead>
        <tbody>
          {getPaginatedItems(data).map((row, index) => (
            <React.Fragment key={index}>
              <tr
                className={`table-data-row ${
                  selectedRowIndex === index && 'border-b-0'
                }`}
                style={{
                  borderBottom:
                    selectedRowIndex === index ? 'none' : '1px solid #e5e7eb',
                }}
              >
                <td className='p-2'>
                  <div className='relative w-10 h-10 my-2'>
                    <img
                      src={row['Image_url']}
                      alt='Prod img'
                      className='absolute inset-0 object-contain w-full h-full'
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                  </div>
                </td>
                <td className='p-2'>{row['Form_Factor']}</td>
                <td className='p-2'>{row['Generic_Name']}</td>
                <td className='p-2'>{row['Brand']}</td>
                <td className='p-2'>
                  {row['Model_Name']}
                  {/* model name */}
                </td>
                {/* <td className='border-b p-2'>{row['MRP']}</td> */}
                <td className='p-2'>{row['Discounted_Price']}</td>
                <td className='p-2'>{row['prev_Discounted_Price']}</td>
                <td className='p-2'>
                  {
                    row['Discount_diff_percentage'] !== null &&
                    row['Discount_diff_percentage'] !== undefined
                      ? row['Discount_diff_percentage'].toFixed(2)
                      : '0' // or any default value you want to display
                  }
                </td>
                <td className={`p-2`}>
                  <div className={getRowClass(row)}>
                    {getIcon(row['Alert'])} {row['Alert']}
                  </div>
                </td>
                <td
                  className='p-2 hover:cursor-pointer details-button'
                  onClick={() => handleDetailsClick(index)} // Pass the rowIndex to the handleDetailsClick function
                >
                  Details{' '}
                  {selectedRowIndex === index ? (
                    <i className='fa-solid fa-arrow-up ml-1'></i>
                  ) : (
                    <i className='fa-solid fa-arrow-down ml-1'></i>
                  )}
                </td>
              </tr>
              {/* Conditionally render SellingPriceGraph for the selected row */}
              {selectedRowIndex === index && productName && (
                <tr className='table-data-row'>
                  <td colSpan={columns.length + 1}>
                    <SellingPriceGraph
                      isOpen={isSellingGraphOpen}
                      productName={productName}
                      industry={industry}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {/* UpgradeYourPlan modal */}
      {isUpgradeModalOpen && <UpgradeYourPlan onClose={closeUpgradeModal} />}
    </div>
  );
};

// export default PriceMonitoringDataTable;

PriceMonitoringDataTable.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(PriceMonitoringDataTable);
