import { Link, Navigate, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login, clickForgotPassword } from '../../actions/auth';
import { toast } from 'react-toastify';
import '../../css/onBoarding/login.css';
import '../../css/onBoarding/register.css';
import Loader from '../loader/Loader';
// import '../../css/';

// Image Imports
import color_logo from '../../img/color_logo.png';
import email_icon from '../../img/email.png';
import password_icon from '../../img/password.png';
import bg_img_1 from '../../img/bg_img_1.png';

let Login = ({ login, isAuthenticated, clickForgotPassword }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);

  // Destructure formData
  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    login(email, password)
      .then((e) => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    // console.log(formData);
    // console.log('Success');
  };

  const handleClickForgotPassword = async (e) => {
    e.preventDefault();

    // console.log('click on event');

    // Call action here
    await clickForgotPassword();
    // console.log('below action function');

    //navigate to link page
    navigate('/email-forgot-password');
    // console.log('below navigate');
  };

  // Redirect if logged in
  if (isAuthenticated) {
    // toast.success('Logged In Success');
    return <Navigate to='/homepage' />;
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={`flex h-screen`}>
          {/* Left Area */}
          <div
            className={`w-full lg:w-2/5 flex-shrink-0 flex flex-col justify-start left-area`}
          >
            <div className='mb-4 flex justify-center items-center div-1'>
              {/* Logo Image - Horizontally Centered */}
              <img
                src={color_logo}
                alt='logo'
                className='color-logo-register'
              />
            </div>

            {/* Second Div */}
            <div className='flex flex-col second-div-wrapper items-center justify-center div-2 flex-grow px-24'>
              <h2 className='form-heading'>Login</h2>
              <span className='sub-heading'>
                Welcome back! Log in to continue
              </span>
              <form
                className='w-full'
                onSubmit={(e) => onSubmit(e)}
                method='POST'
              >
                <input type='hidden' name='remember' defaultValue='true' />

                <div className='mb-4 relative'>
                  <img
                    src={email_icon}
                    alt='User Icon'
                    className='absolute left-0 top-1/2 transform -translate-y-1/2 h-6 w-6 text-gray-500 input-icon-register'
                    style={{ zIndex: 1 }}
                  />
                  <input
                    id='email-address'
                    name='email'
                    type='email'
                    value={email}
                    onChange={(e) => onChange(e)}
                    autoComplete='email'
                    required
                    placeholder='Email'
                    className='register-input-field bg-inherit w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative pl-4'
                  />
                </div>
                <div className='mb-4 relative'>
                  <img
                    src={password_icon}
                    alt='User Icon'
                    className='absolute left-0 top-1/2 transform -translate-y-1/2 h-6 w-6 text-gray-500 input-icon-register'
                    style={{ zIndex: 1 }}
                  />
                  <input
                    id='password'
                    name='password'
                    type='password'
                    value={password}
                    onChange={(e) => onChange(e)}
                    autoComplete='current-password'
                    required
                    placeholder='Password'
                    className='password-input bg-inherit w-full border-b-2 border-gray-400 focus:border-blue-600 focus:ring-0 relative pl-4'
                  />
                </div>

                <div className='text-sm flex justify-end mt-2'>
                  <Link
                    to='/email-forgot-password'
                    className='forgot-password hover:text-hover_2-10'
                    onClick={(e) => handleClickForgotPassword(e)}
                  >
                    Forgot your password?
                  </Link>
                </div>

                <button
                  type='submit'
                  className='login-button py-2 px-4 rounded-lg w-full mb-4 focus:ring-2 focus:ring-offset-2 focus:ring-button_ring-10 tracking-wider focus:outline-none'
                >
                  Login
                </button>
              </form>
              <p className='no-account'>
                Don't have an account?{' '}
                <Link to='/register' className='login-link hover:no-underline'>
                  Register
                </Link>
              </p>
            </div>
          </div>

          {/* Right Area */}
          <div className='flex-grow flex flex-col items-center justify-center right-side-login'>
            <h2 className='login-right-side-heading'>Welcome to Price Now</h2>
            <img
              src={bg_img_1}
              className='bg-img-1'
              alt='background-banner-login'
            />
            <h5 className='login-right-side-footer'>
              Maximize eCommerce revenue with Real-Time price alerts, analysis,
              and stock updates
            </h5>
          </div>
        </div>
      )}
    </>
  );
};

login.PropTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login, clickForgotPassword })(Login);
// export default Login;
