import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';

// CSS
import '../../../css/dropdowns/multiSelectDropdown.css';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const MultiSelectDropdown = ({
  platformValues,
  selectedplatform,
  setSelectedplatform,
  currentPlatformSelection,
  setCurrentPlatformSelection,
  subscribedPlatformsId,
}) => {
  // console.log('selected ... ck', selectedplatform);
  // console.log('platformValues ... ck', platformValues);

  // console.log('multi select subscribedPlatformsId', subscribedPlatformsId);

  const handleSelect = (value, e) => {
    // Prevent the checkbox from being unchecked when clicking on it
    // e.preventDefault();
    // Check if the value is already selected
    if (selectedplatform.includes(value)) {
      // If selectedplatform, remove it from the array
      setSelectedplatform(selectedplatform.filter((item) => item !== value));
    } else {
      // If not selectedplatform, add it to the array
      setSelectedplatform([...selectedplatform, value]);
    }

    if (currentPlatformSelection.includes(value)) {
      // If currentPlatformSelection, remove it from the array
      setCurrentPlatformSelection(
        currentPlatformSelection.filter((item) => item !== value)
      );
    } else {
      // If not currentPlatformSelection, add it to the array
      setCurrentPlatformSelection([...currentPlatformSelection, value]);
    }
  };

  return (
    <Listbox as='div'>
      {({ open }) => (
        <>
          <div className='relative max-w-full rounded-md bg-white custom-css-multiselect-dd'>
            <Listbox.Button className='relative cursor-default py-1.5 pr-8 pl-2 text-left text-gray-900  focus:outline-none sm:text-sm sm:leading-6 truncate max-w-full'>
              <span className='flex items-center'>
                <span className='ml-2 block truncate'>Select Platforms</span>
              </span>
              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                <i
                  className='fa-solid fa-chevron-down text-gray-500'
                  style={{ fontSize: '15px' }}
                ></i>
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options className='absolute z-10 mt-3 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm min-w-min'>
                {platformValues.map((person) => (
                  <Listbox.Option
                    key={person.id}
                    className={({ active }) =>
                      classNames(
                        active
                          ? 'bg-hover_4-10 text-gray-700'
                          : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                        subscribedPlatformsId.includes(person.id) ||
                          person.disable
                          ? 'opacity-50 pointer-events-none'
                          : ''
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className='flex items-center'>
                          <input
                            type='checkbox'
                            checked={
                              selectedplatform.includes(person.id) ||
                              subscribedPlatformsId.includes(person.id)
                            }
                            onChange={(e) => handleSelect(person.id, e)}
                            className='form-checkbox text-indigo-600 platform-checkbox'
                            disabled={subscribedPlatformsId.includes(person.id)}
                          />
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'ml-3 block truncate'
                            )}
                          >
                            {person.platform_name}
                          </span>
                        </div>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default MultiSelectDropdown;
