// UpgradeYourPlan.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Image
import upgrade_icon from '../../img/upgrade_icon.png';

// CSS
import '../../css/modals/UpgradeYourPlan.css';

const UpgradeToPro = ({ onClose }) => {
  return (
    <div
      className='relative z-50'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'></div>

      <div className='fixed inset-0 z-50 w-screen overflow-y-auto'>
        <div className='flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0 mobile-view-upgrade-to-pro-modal'>
          <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-sm sm:max-w-sm'>
            {/* Image icon centered aligned */}
            <img
              src={upgrade_icon}
              alt='Upgrade Icon'
              className='mx-auto mt-6 upgrade-icon'
            />

            <div className='bg-white px-4 '>
              <div className='sm:flex sm:items-start'>
                <div className='mt-4 text-center sm:text-left'>
                  {/* Heading text centered aligned */}
                  <h3 className='upgrade-plan-sub-heading' id='modal-title'>
                    Upgrade to Pro plan!
                  </h3>
                  {/* Subheading text centered aligned */}
                  <div className='mt-2'>
                    <p className='upgrade-plan-sub-text'>
                      Take your experiance to the next level, Upgrade to our Pro
                      plan to access all the features.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='bg-gray-50 mt-2 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 justify-center'>
              {/* Center aligned buttons */}
              <Link to='/pd-plans'>
                <button
                  type='button'
                  className='upgrade-plan-upgrade-button inline-flex w-full justify-center rounded-md px-3 py-2 sm:ml-3 sm:w-auto focus:outline-none focus:ring-offset-2 focus:ring-button_ring-10 focus:ring-2 tracking-wider'
                >
                  Upgrade
                </button>
              </Link>
              <button
                type='button'
                onClick={onClose}
                className='upgrade-plan-cancel-button inline-flex w-full justify-center rounded-md bg-white px-3 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeToPro;
