import React, { useState, useEffect } from 'react';
import { Sidebar } from '../sidebar/Sidebar';
import Navbar from '../navbar/Navbar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Analytics from './Analytics';
import AnalyticPremium from './AnalyticPremium';
import AnalyticPro from './AnalyticPro'; // Import AnalyticPro component

const AnalyticPlanType = ({ auth: { isAuthenticated, user } }) => {
  const [planType, setPlanType] = useState(null);

  useEffect(() => {
    if (user) {
      setPlanType(user.plan_type);
    }
  }, [user]);

  // console.log('planType', planType);

  // Conditional rendering based on planType
  let analyticComponent;
  switch (planType) {
    case 'free':
      analyticComponent = <Analytics />;
      break;
    case 'premium':
      analyticComponent = <AnalyticPremium />;
      break;
    case 'pro':
      analyticComponent = <AnalyticPro />;
      break;
    default:
      analyticComponent = null; // Handle unknown planType if needed
      break;
  }

  return <>{analyticComponent}</>;
};

AnalyticPlanType.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(AnalyticPlanType);
