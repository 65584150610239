import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Components
import Navbar from '../navbar/Navbar';
import Loader from '../loader/Loader';

// Images
import imageSrc from '../../img/landingpage_image.png';

// CSS
import '../../css/landing/landing.css';

const LandingPage = ({ auth: { isAuthenticated, user } }) => {
  const [paidButton, setPaidButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add('with-background');

    return () => {
      document.body.classList.remove('with-background');
    };
  }, []);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();

        // Save the IP address in local storage
        localStorage.setItem('userIp', data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    // Check if the user's IP address is already saved in local storage
    const savedUserIp = localStorage.getItem('userIp');
    if (!savedUserIp) {
      fetchIpAddress();
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && user) {
      setIsLoading(true);
      // console.log('user Authenticated');
      // console.log('user', user);

      if (user.is_paid) {
        // console.log('paid User', user.is_paid);
        setPaidButton(true);
        setIsLoading(false);
      } else {
        // console.log('no is_paid user');
        setIsLoading(false);
      }
    }
  }, [isAuthenticated, user]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='landing-page h-screen w-full flex flex-col'>
          <Navbar />
          <div className='flex-grow flex items-center justify-center p-4'>
            <div className='landing-page-box flex flex-col md:flex-row bg-white rounded-xl shadow-xl'>
              <div className='w-full md:w-1/2 p-10 left-div-wrapper'>
                <img
                  src={imageSrc}
                  alt='Landing page image'
                  className='max-w-full h-auto'
                />
              </div>
              <div className='w-full md:w-1/2 p-10 right-div-wrapper'>
                <h2 className='landing-page-header-text mb-10 justify-start'>
                  Harnessing the Power of Data
                  <br />
                  to
                  <span className='landing-page-special-text'> Boost</span> the
                  Sales
                </h2>
                <div>
                  <h3 className='landing-page-title-text'>Price Monitoring</h3>
                  <p className='landing-page-sub-text mt-2 flex justify-between w-full'>
                    Empower your business with our cutting-edge tool, always
                    ready to alert you the moment your competitors make a move
                    with their prices. Stay steps ahead, seize every
                    opportunity, and dominate your market!
                  </p>
                </div>
                <div>
                  <h3 className='landing-page-title-text mt-3'>
                    Product Out of Stock
                  </h3>
                  <p className='landing-page-sub-text mt-2 flex justify-between w-full'>
                    Stay one step ahead with our real-time out-of-stock product
                    reports. Never miss a chance to shine when your competitor's
                    products are off the shelves. Your success, our priority.
                  </p>
                </div>
                <div>
                  <h3 className='landing-page-title-text mt-3'>
                    New Product Launch
                  </h3>
                  <p className='landing-page-sub-text font-medium mt-2 flex justify-between'>
                    Dive into market trends, pinpoint what's sizzling hot, and
                    craft a winning strategy for your product positioning. Get
                    ahead of the curve and lead the market with confidence.
                  </p>
                </div>
                <div className='flex sm:mt-10 2xl:16 custom-view'>
                  {!paidButton ? (
                    <>
                      <div className='mr-6 paid-button-wrapper'>
                        <Link to='/analytics' className='hover:no-underline'>
                          <button className='flex justify-center items-center landing-page-button landing-page-button-1 py-2 px-4 rounded-lg w-full mb-4 focus:ring-2 focus:ring-offset-2 focus:ring-button_ring-10 tracking-wider focus:outline-none'>
                            Try for free
                          </button>
                        </Link>
                      </div>
                      <div>
                        <Link to='/pd-plans' className='hover:no-underline'>
                          <button className='flex justify-center items-center landing-page-button landing-page-button-1 py-2 px-4 rounded-lg w-full mb-4 focus:ring-2 focus:ring-offset-2 focus:ring-button_ring-10 tracking-wider focus:outline-none price-plan-button'>
                            Price Plan
                          </button>
                        </Link>
                      </div>
                    </>
                  ) : (
                    <div>
                      <Link to='/analytics' className='hover:no-underline'>
                        <button className='flex justify-center items-center landing-page-button landing-page-button-1 py-2 px-4 rounded-lg w-full mb-4 focus:ring-2 focus:ring-offset-2 focus:ring-button_ring-10 tracking-wider focus:outline-none'>
                          Visit Analytics
                        </button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(LandingPage);
