import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../img/logo.png';
import page404_icon from '../../img/404_icon.png';
import '../../css/onBoarding/page404.css';

const Page404 = () => {
  return (
    <div className='flex flex-col min-h-screen  w-full page'>
      <div className='flex justify-center pt-4 sm:pt-4 2xl:pt-7'>
        <img src={Logo} alt='Ecom Next Price Now' className='logo-style-1' />
      </div>
      <div className='flex flex-grow items-center justify-center'>
        <div className='text-center flex flex-col items-center'>
          <img src={page404_icon} alt='Page 404' className='img-style-1' />
          <h2 className=' page-404-heading pt-9 font-medium sm:pt-9 2xl:pt-14'>
            Page Not Found
          </h2>

          <p className='flex justify-center page-404-note pt-9 font-medium sm:pt-9 2xl:pt-14'>
            We're sorry, the page you requested could<br></br> not be found
            please go back to the home<br></br> page.
          </p>
          <div className='flex justify-center sm:pt-9 2xl:pt-14'>
            <Link to='/login' className='hover:no-underline'>
              <button
                type='submit'
                className='flex justify-center items-center  page404-button-link  page404-button-link-1 px-4 py-2   rounded-lg w-full sm:w-auto focus:ring-2 focus:ring-offset-2 focus:ring-button_ring-10 tracking-wider focus:outline-none'
              >
                Go To Login
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page404;
